import { Injectable } from "@angular/core";
import { Body, Get, Path, Post, RestService } from "ngx-rest-service";
import { GenericResponse } from "../models";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProductInventoryService extends RestService {
  @Get("best-sellers/categories/{countryCode}")
  getBestSellerCategories(@Path("countryCode") countryCode: string,): Observable<GenericResponse<string[]>> {
    return null;
  }

  @Post("inventory/bulk-delete/{marketplaceId}")
  deleteBulkProduct(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: string[]
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Post("inventory/best-selling-products/{marketId}/{pageNumber}/{pageSize}")
  getBestSellerProducts(
    @Path("marketId") marketId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Body request: { startDate: Date | string; endDate: Date | string }
  ): Observable<GenericResponse<any>> {
    return null;
  }
}
