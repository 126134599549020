<section class="p-10">
  <div class="container-fluid vertical-center">
    <div class="row">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="card">
          <div class="body p-10 text-center">
            <div class="top-right-link">
              <a (click)="redirectToSignin()">{{welcome_page_go_back_login}}</a>
            </div>
            <img
              src="assets/images/email_confirm_290.png"
              style="max-width: 70%; max-height: 70%"
            />
            <h5 class="mt-lg-3" style="color: black; font-weight: bolder">
              {{ welcomeToDropyOnlineLetsStart }}
            </h5>
            <br />

            <br />
            <button
              style="padding: 10px 20px; background-color: #007bff; color: white; border: none; cursor: pointer; border-radius: 5px;"
              (click)="redirectToWelcomePage()"
            >
            {{nextButton}}
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-2"></div>
    </div>
  </div>
</section>

<style>
  .top-right-link {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .top-right-link a {
    text-decoration: none;
    color: primary;
    font-weight: bold;
  }

  .top-right-link a:hover {
    text-decoration: underline;
  }
</style>
