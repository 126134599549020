import {
  BrandBlackListRequest,
  CategoryBlackListRequest,
  CreateCategoryBlackList,
  RestrictedKeywordRequest,
} from "../models";
import {AllowedBlackListRequest} from "../models/restriction/allowed-black-list-request";
import {RestrictedBlackListRequest} from "../models/restriction/restricted-black-list-request";
import {KeywordBlackListRequest} from "../models/restriction/keyword-black-list-request";

export class GetKeywordBlackListByMarketPlaceId {
  static readonly type =
    "[Restriction] Get Keyword Black List By MarketPlaceId";

  constructor(public readonly payload: KeywordBlackListRequest) {}
}

export class SearchKeywordBlackList {
  static readonly type =
      "[Restriction] Search Keyword Black List";

  constructor(public readonly payload: KeywordBlackListRequest) {}
}

export class CreateKeywordBlackList {
  static readonly type = "[Restriction] Create Keyword Black List";

  constructor(public readonly payload: RestrictedKeywordRequest) {}
}


export class GetAllowedBrandBlackList {
  static readonly type = "[Restriction] Get Allowed Brand Black List";

  constructor(public readonly payload: AllowedBlackListRequest) {}
}

export class SearchAllowedBrandBlackList {
  static readonly type = "[Restriction] Search Allowed Brand Black List";

  constructor(public readonly payload: AllowedBlackListRequest) {}
}

export class GetRestrictedBrandBlackList {
  static readonly type = "[Restriction] Get Restricted Brand Black List";

  constructor(public readonly payload: RestrictedBlackListRequest) {}
}

export class SearchRestrictedBrandBlackList {
  static readonly type = "[Restriction] search Restricted Brand Black List";

  constructor(public readonly payload: RestrictedBlackListRequest) {}
}

export class CreateRestrictedBrandBlackList {
  static readonly type = "[Restriction] Create Restricted Brand Black List";

  constructor(public readonly payload: BrandBlackListRequest) {}
}

export class CreateAllowedBrandBlackList {
  static readonly type = "[Restriction] Create Allowed Brand Black List";

  constructor(public readonly payload: BrandBlackListRequest) {}
}

export class GetCategoryBlackListWithPage {
  static readonly type = "[Restriction] Get Category Black List With Page";

  constructor(public readonly payload: CategoryBlackListRequest) {}
}

export class SearchCategoryBlackListWithPage {
  static readonly type = "[Restriction] Search Category Black List With Page";

  constructor(public readonly payload: CategoryBlackListRequest) {}
}

export class CreateCategoryBlack {
  static readonly type = "[Restriction] Create Category Black List";

  constructor(public readonly payload: CreateCategoryBlackList) {}
}
