<app-page-loader></app-page-loader>
<div
  *ngIf="
    !(
      _router.url.includes('/authentication') ||
      _router.url.includes('/invitation') ||
      !control
    )
  "
>
  <app-header></app-header>
  <app-sidebar></app-sidebar>
</div>

<app-right-sidebar></app-right-sidebar>
<router-outlet></router-outlet>
