import { UserProductRequest } from "../models/inventory";
import { ApprovePendingRequest } from "../models/inventory/requests/approve-pending-request";
import { RemovePendingRequest } from "../models/inventory/requests/remove-pending-request";
import { RemoveImportedToStoreRequest } from "../models/inventory/requests/remove-imported-to-store-request";
import { RemoveFromStoreRequest } from "../models/inventory/requests/remove-from-store-request";
import { RemoveInQueueRequest } from "../models/inventory/requests/remove-in-queue-request";

export class GetImportedToStores {
  static readonly type = "[Inventory] Get Imported To Store Products";
  constructor(public readonly payload: UserProductRequest) {}
}

export class GetDoesntMeetCriterias {
  static readonly type = "[Inventory] Get Doesnt Meet Criteria Products";
  constructor(public readonly payload: UserProductRequest) {}
}

export class GetInQueues {
  static readonly type = "[Inventory] Get In Queue Products";
  constructor(public readonly payload: UserProductRequest) {}
}
export class GetPendingApprovals {
  static readonly type = "[Inventory] Get Pending Approval Products";
  constructor(public readonly payload: UserProductRequest) {}
}

export class ApprovePending {
  static readonly type = "[Inventory] Approve Pending Asins";

  constructor(
    public readonly payload: ApprovePendingRequest,
    public readonly type: number
  ) {}
}

export class ApproveAdvancedSearchPending {
  static readonly type = "[Inventory] Approve Advanced Search Pending Asins";

  constructor(public readonly payload: any) {}
}

export class RemovePending {
  static readonly type = "[Inventory] Remove Pending Asins";

  constructor(
    public readonly payload: RemovePendingRequest,
    public readonly type: number
  ) {}
}

export class RemoveImportedToStore {
  static readonly type = "[Inventory] Remove Imported To Store";

  constructor(
    public readonly payload: RemoveImportedToStoreRequest,
    public readonly type: number
  ) {}
}

export class RemoveDoesntMeetCriteria {
  static readonly type = "[Inventory] RemoveDoesntMeetCriteria";

  constructor(
    public readonly payload: RemoveImportedToStoreRequest,
    public readonly type: number
  ) {}
}

export class RemoveInQueue {
  static readonly type = "[Inventory] RemoveInQueue";

  constructor(
    public readonly payload: RemoveImportedToStoreRequest,
    public readonly type: number
  ) {}
}

export class RemoveFromStore {
  static readonly type = "[Inventory] Remove From Store";

  constructor(public readonly payload: RemoveFromStoreRequest) {}
}

export class InquireProductDetail {
  static readonly type = "[Inventory] Get Product Detail";

  constructor(public readonly payload: string) {}
}

export class GetOrderDetails {
  static readonly type = "[Inventory] Get Order Detail";

  constructor(public readonly payload: string) {}
}

export class GetCategoriesExcel {
  static readonly type = "[Inventory] Get Categories Excel";

  constructor(public readonly payload: any) {}
}

export class AdvancedSearchApproval {
  static readonly type = "[Inventory] AdvancedSearchApproval";

  constructor(public readonly payload: any) {}
}

export class RemoveAdvancedSearchApproval {
  static readonly type = "[Inventory] RemoveAdvancedSearchApproval";

  constructor(public readonly payload: any) {}
}
export class AdvancedSearchImportedStore {
  static readonly type = "[Inventory] AdvancedSearchImportedStore";

  constructor(public readonly payload: any) {}
}

export class RemoveAdvancedSearchImportedStore {
  static readonly type = "[Inventory] RemoveAdvancedSearchImportedStore";

  constructor(public readonly payload: any) {}
}
