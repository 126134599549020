import { Injectable } from "@angular/core";
import { Body, Delete, Get, Path, Post, RestService } from "ngx-rest-service";
import { GenericResponse, PageableResponse } from "../models";
import { Observable } from "rxjs";
import { UserProductWithProductDto } from "../models/inventory";
import { ApprovePendingRequest } from "../models/inventory/requests/approve-pending-request";
import { RemovePendingRequest } from "../models/inventory/requests/remove-pending-request";
import { RemoveImportedToStoreRequest } from "../models/inventory/requests/remove-imported-to-store-request";
import { RemoveFromStoreRequest } from "../models/inventory/requests/remove-from-store-request";
import { eHTTP } from "ngx-rest-service/lib/rest-decorators/rest.symbols";
import Observe = eHTTP.Observe;

@Injectable({
  providedIn: "root",
})
export class InventoryService extends RestService {
  @Get("inventory/imported-to-store/{marketplaceId}/{pageNumber}/{pageSize}")
  getImportedToStores(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<UserProductWithProductDto>> {
    return null;
  }

  @Get(
    "inventory/does-not-meet-criteria/{marketplaceId}/{pageNumber}/{pageSize}"
  )
  getDoesntMeetCriterias(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<UserProductWithProductDto>> {
    return null;
  }

  @Get("inventory/in-queue/{marketplaceId}/{pageNumber}/{pageSize}")
  getInQueues(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<UserProductWithProductDto>> {
    return null;
  }

  @Get("inventory/pending-approval/{marketplaceId}/{pageNumber}/{pageSize}")
  getPendingApprovals(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<UserProductWithProductDto>> {
    return null;
  }

  @Post("inventory/approve-pending/{marketplaceId}/{type}")
  approvePending(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Post("inventory/approve-advanced-search-pending-approval/{marketplaceId}")
  approveAdvancedSearchPending(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: string[]
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Post(
    "inventory/pending-approval/advanced-search/{marketplaceId}/{pageNumber}/{pageSize}"
  )
  advancedSearchPendingApproval(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Body request: any
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Post(
    "inventory/imported-to-store/advanced-search/{marketplaceId}/{pageNumber}/{pageSize}"
  )
  advancedSearchImportedStore(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Body request: any
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete("inventory/discard-pending/{marketplaceId}/{type}")
  removePending(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete(
    "inventory/pending-approval/advanced-search/delete-all/{marketplaceId}"
  )
  removeAdvancedSearchPendingApproval(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: any
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete("inventory/remove-from-imported-store/{marketplaceId}/{type}")
  removeImportedToStore(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete("inventory/remove-from-does-not-meet-criteria/{marketplaceId}/{type}")
  removeDoesntMeetCriteria(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete("inventory/remove-from-in-queue/{marketplaceId}/{type}")
  removeFromInqueue(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("type") type: string,
    @Body request: string[]
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Delete("inventory/remove-from-store/{marketplaceId}")
  removeFromStore(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: RemoveFromStoreRequest
  ): Observable<GenericResponse<null>> {
    return null;
  }

  @Get("orders/detail/{marketId}/{orderId}")
  getOrderDetails(
    @Path("marketId") marketId: string,
    @Path("orderId") orderId: string
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Get("inventory/categories-excel/{marketplaceId}")
  getCategoriesExcel(
    @Path("marketplaceId") marketPlaceId: string
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Delete(
    "inventory/imported-to-store/advanced-search/delete-all/{marketplaceId}"
  )
  removeAdvancedSearchImportedStore(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: any
  ): Observable<GenericResponse<null>> {
    return null;
  }

  //  @Delete("GetNewExcelServiceTest/100000")
  // removeAdvancedSearchImportedStore(
  //   @Path("marketplaceId") marketPlaceId: string,
  //   @Body request: any
  // ): Observable<GenericResponse<null>> {
  //   return null;
  // }
}
