import { Injectable } from "@angular/core";
import { Body, Get, Path, Put, Query, RestService } from "ngx-rest-service";
import {
  Account,
  FilterResponse,
  GenericResponse,
  MarketPlace,
  PageableResponse,
  PriceTableModel,
  SubscriptionDetail,
} from "../models";
import { Observable } from "rxjs";
import { UserProductWithProductDto } from "../models/inventory";
import { ReceivedPaymentsResponseDto } from "../../account/my-received-payments-table/my-received-payments-table.component";

@Injectable({
  providedIn: "root",
})
export class AccountManagementService extends RestService {
  @Get("account/marketplaces")
  getMarketPlaces(): Observable<GenericResponse<MarketPlace[]>> {
    return null;
  }

  @Get("account")
  getAccountInformation(): Observable<GenericResponse<Account>> {
    return null;
  }

  @Get("account/subscription-details")
  getSubscriptionDetails(): Observable<GenericResponse<SubscriptionDetail>> {
    return null;
  }

  @Get(
    "account/marketplaces/received-payments/{marketplaceId}/{pageNumber}/{pageSize}"
  )
  getReceivedPayments(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<GenericResponse<ReceivedPaymentsResponseDto>> {
    return null;
  }

  @Get("inventory/pending-approval/{marketplaceId}/{pageNumber}/{pageSize}")
  getPendingApprovals(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<UserProductWithProductDto>> {
    return null;
  }

  @Get("account/marketplaces/{marketplaceId}/filters")
  getFilters(
    @Path("marketplaceId") marketplaceId: string
  ): Observable<GenericResponse<FilterResponse>> {
    return null;
  }

  @Put("account/marketplaces/{marketplaceId}/filters")
  putFilters(
    @Path("marketplaceId") marketplaceId: string,
    @Body request: FilterResponse
  ): Observable<GenericResponse<FilterResponse>> {
    return null;
  }

  @Get("account/marketplaces/prices/{marketplaceId}/{type}")
  getPrices(
    @Path("marketplaceId") marketplaceId: string,
    @Path("type") type: number
  ): Observable<GenericResponse<PriceTableModel>> {
    return null;
  }

  @Put("account/marketplaces/update-marketplace-prices/{marketplaceId}/{type}")
  updatePrices(
    @Path("marketplaceId") marketplaceId: string,
    @Path("type") type: string,
    @Body request: PriceTableModel
  ): Observable<GenericResponse<PriceTableModel>> {
    return null;
  }
}
