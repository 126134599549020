import { Injectable } from "@angular/core";
import {Body, Get, Post, RestService} from "ngx-rest-service";
import { GenericResponse } from "../models";
import { Observable } from "rxjs";
import { ApprovePendingRequest } from "../models/inventory/requests/approve-pending-request";

@Injectable({
  providedIn: "root",
})
export class ProductDetailService extends RestService {
  @Post("inventory/products/detail")
  getProductDetail(
    @Body request: { asin: string; marketplaceId: string; countryCode: string }
  ): Observable<GenericResponse<string[]>> {
    return null;
  }
}
