import { Store } from "@ngxs/store";
import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { GetPricesTable, GetSalesSettings } from "../actions";
import { catchError, mapTo, take } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DropyHouseSettingsResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve() {
    return this.store
      .dispatch([new GetSalesSettings(), new GetPricesTable(2)])
      .pipe(
        catchError((err) => of(err)),
        take(1),
        mapTo(null)
      );
  }
}
