import { Store } from "@ngxs/store";
import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  GetBalance,
  GetCard,
  GetCreditHistory,
  GetOpenInvoices,
  GetPaidInvoices,
} from "../actions";
import { catchError, mapTo, take } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DropyHouseInvoiceResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve() {
    return this.store.dispatch([new GetPaidInvoices(null)]).pipe(
      catchError((err) => of(err)),
      take(1),
      mapTo(null)
    );
  }
}
