<nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'">
  <div class="container-fluid">
    <div class="navbar-header">
      <!-- <a
        href="#"
        onClick="return false;"
        class="navbar-toggle collapsed"
        data-toggle="collapse"
        data-tmaarget="#navbar-collapse"
        aria-expanded="false"
        (click)="isNavbarCollapsed = !isNavbarCollapsed"
        aria-expanded="false"

      ></a> -->
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
      (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a
        href="#"
        onClick="return false;"
        class="bars"
        (click)="mobileMenuSidebarOpen($event, 'overlay-open')"
      ></a>
      <a
        class="navbar-brand"
        routerLink="dashboard/main"
        style="margin-left: -12px !important"
      >
        <span class="logo-name">Dropy Online</span>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button
            mat-icon-button
            (click)="callSidemenuCollapse()"
            class="sidemenu-collapse"
          >
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- #START# Notifications-->
        <li class="dropdown">
          <button
            mat-icon-button
            data-toggle="dropdown"
            class="nav-notification-icons"
          >
            <img
              alt="selected_marketplace_country_image"
              src="assets/images/{{ selectedCountry }}.png"
              height="10"
            />
            <span class="menu-info">
              <span
                class="menu-title ml-1"
                [ngClass]="isNavbarShow ? 'text-light' : 'text-dark'"
                >{{ selectedCountry | uppercase }}</span
              >
            </span>
          </button>
          <ul class="dropdown-menu pullDown">
            <li class="header">Language Selection</li>
            <li class="body">
              <ul
                class="menu"
                style="position: relative; max-width: 600px; max-height: 200px"
                [perfectScrollbar]
              >
                <li>
                  <a style="cursor: pointer" (click)="selectCountry('en')">
                    <span class="menu-info">
                      <span class="menu-title">
                        <img
                          alt="selected_marketplace_country_image"
                          src="assets/images/en.png"
                          height="10"
                        />
                        EN(English)</span
                      >
                    </span>
                  </a>
                </li>
                <li>
                  <a style="cursor: pointer" (click)="selectCountry('tr')">
                    <span class="menu-info">
                      <span class="menu-title">
                        <img
                          alt="selected_marketplace_country_image"
                          src="assets/images/tr.png"
                          height="10"
                        />
                        TR(Türkçe)</span
                      >
                    </span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- #END# Notifications-->
        <!-- #START# Notifications-->
        <li class="dropdown ml-3">
          <button
            mat-icon-button
            data-toggle="dropdown"
            class="nav-notification-icons"
          >
            <mat-icon>store</mat-icon>
            <span class="menu-info">
              <!-- <span
                class="menu-title"
                [ngClass]="isNavbarShow ? 'text-light' : 'text-dark'"
                >{{ selectedMarketPlaceName }}</span
              >-->
            </span>
          </button>
          <ul class="dropdown-menu pullDown">
            <li class="header">
              {{ "store_selection" | translate }}
              <p class="menu">
                {{ "selected_store" | translate }} &nbsp;
                <img
                  src="assets/images/{{ selectedMarketPlaceCountry }}.png"
                  height="12"
                />
                &nbsp; {{ selectedMarketPlaceName }}
              </p>
            </li>
            <li class="body">
              <ul
                class="menu"
                style="position: relative; max-width: 600px; max-height: 200px"
                [perfectScrollbar]
              >
                <ng-container
                  *ngFor="let marketPlace of (marketPlaces$ | async)?.Result"
                >
                  <li>
                    <a (click)="setSelectedMarketPlace(marketPlace)">
                      <span class="menu-info">
                        <span class="menu-title">
                          <img
                            src="assets/images/{{
                              marketPlace.marketplaceCountry
                            }}.png"
                            height="12"
                          />
                          &nbsp; {{ marketPlace.marketplaceName }}</span
                        >
                      </span>
                    </a>
                  </li>
                </ng-container>

                <li>
                  <a
                    routerLink="/invitation/welcome-page"
                    onClick="return false;"
                  >
                    <span class="menu-info">
                      <span class="menu-title">
                        &nbsp;&nbsp;
                        <img src="assets/images/plus.png" height="12" />
                        &nbsp;&nbsp; {{ "add_new_store" | translate }}
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- #END# Notifications-->
        <li class="dropdown user_profile">
          <a
            href="#"
            onClick="return false;"
            data-toggle="dropdown"
            role="button"
            class="pt-0"
          >
            <img
              src="assets/images/DropyOnline_1000x1000.png"
              width="32"
              height="32"
              alt="User"
            />
          </a>
          <ul class="dropdown-menu pullDown">
            <li class="body">
              <ul class="user_dw_menu">
                <li>
                  <a routerLink="/account/profile" onClick="return false;">
                    <mat-icon aria-hidden="false" class="mr-2">person</mat-icon
                    >{{ "profile" | translate }}
                  </a>
                </li>
                <li>
                  <a href="#" onClick="return false;" (click)="togglePopup($event)">
                    <mat-icon aria-hidden="false" class="mr-2">cast</mat-icon>{{ "blur_mode_presentation_mode" | translate }}
                  </a>
                </li>
                <li>
                  <a (click)="clearCacheAndRedirect()" routerLink="/authentication/signin">
                    <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>{{ "logout" | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button
            mat-icon-button
            (click)="callFullscreen()"
            class="nav-notification-icons"
          >
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- #END# Tasks -->
        <li class="pull-right">
          <button
            mat-icon-button
            (click)="toggleRightSidebar()"
            class="nav-notification-icons"
          >
            <mat-icon id="settingBtn">settings</mat-icon>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!-- Pop-up Overlay -->
<div *ngIf="isPopupOpen" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); display: flex; align-items: center; justify-content: center; z-index: 1000;">
  <div style="background-color: white; padding: 20px; border-radius: 8px; box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); text-align: left; max-width: 300px; width: 100%; position: relative;">
    <!-- X Button to Close the Popup -->
    <button (click)="closePopupWithoutSave()" style="position: absolute; top: 10px; right: 10px; background: transparent; border: none; font-size: 18px; cursor: pointer;">&times;</button>
    <h3 style="margin-top: 20px; text-align: center;">{{ "blur_mode_presentation_mode" | translate }}</h3>
    <h6 style="margin-top: 20px; text-align: center;">{{ "blur_mode_presentation_mode_info" | translate }}</h6>
    <hr style="margin: 20px 0;">
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurUsername" style="margin-right: 8px;"> {{ "blur_mode_user_name" | translate }}
    </label>
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurUserEmail" style="margin-right: 8px;"> {{ "blur_mode_user_email" | translate }}
    </label>
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurUserSubscriptionPackage" style="margin-right: 8px;"> {{ "blur_mode_user_subscription_package" | translate }}
    </label>
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurOrderBuyerInfo" style="margin-right: 8px;"> {{ "blur_mode_order_buyer_info" | translate }}
    </label>
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurSalesOrderNumber" style="margin-right: 8px;"> {{ "blur_mode_sales_order_number" | translate }}
    </label>
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurASIN" style="margin-right: 8px;"> {{ "blur_mode_asin" | translate }}
    </label>
    <label style="display: flex; align-items: center; margin-bottom: 10px;">
      <input type="checkbox" [(ngModel)]="cbBlurSKU" style="margin-right: 8px;"> {{ "blur_mode_sku" | translate }}
    </label>
    <button mat-raised-button color="primary" (click)="saveChangesPopup()" style="margin-top: 15px; width: 100%;">{{ "save" | translate }}</button>
  </div>
</div>


