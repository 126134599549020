import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { CheckoutService } from "src/app/shared/services/checkout.service";

@Component({
  selector: "app-pre-welcome",
  templateUrl: "./pre-welcome.component.html",
  styleUrls: ["./pre-welcome.component.sass"],
})
export class PreWelcomeComponent implements OnInit {
  sessionId: string | null = null;

  setLocalStorageLang: void;
  lang: string;

  welcome_page_go_back_login: string = "";
  welcomeToDropyOnlineLetsStart: string = "";
  nextButton: string = "";


  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.sessionId = params['session_id'];
      if (this.sessionId) {
        this.checkAndSaveSession(this.sessionId);
      } else {
        console.error('No session_id found in the query parameters');
      }
    });

    // Fetch lang value from the query string
    this.route.queryParams.subscribe(params => {
        this.lang = params['lang'] || 'tr'; // Default to 'tr' if lang is not provided
        localStorage.setItem("language", this.lang); // Set lang in local storage
        this.translateService.use(this.lang); // Set the language for translate service
        this.welcome_page_go_back_login = this.translateService.instant("welcome_page_go_back_login");
        this.welcomeToDropyOnlineLetsStart = this.translateService.instant("welcomeToDropyOnlineLetsStart");
        this.nextButton = this.translateService.instant("package_selection_next_button");
  
      });
      this.setLocalStorageLang = localStorage.setItem("language", this.lang);
  }

  checkAndSaveSession(sessionId: string): void {
    this.checkoutService.checkAndSaveSession(sessionId).subscribe(
      response => {
        // Handle the response, e.g., show a success message or redirect
        // You may navigate to a different page or update the UI here
        //this.router.navigate(['/some-success-page']); // Example of redirection
      },
      error => {
        console.error('Error verifying and saving session:', error);
        // Handle error, e.g., show an error message
      }
    );
  }

  redirectToSignin() {
    this.router.navigate(['/authentication/signin']);
  }

  redirectToWelcomePage() {
    this.router.navigate(['/invitation/welcome-page']);
  }
}
