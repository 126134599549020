import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { PageableResponse, RestrictionStateModel } from "../../models";
import { InventoryService } from "../../services/inventory.service";
import {
  GetDoesntMeetCriterias,
  RemoveDoesntMeetCriteria,
  RemoveFromStore,
} from "../../actions";
import { UserProductWithProductDto } from "../../models/inventory";
import { DoesntMeetCriteriaStateModel } from "../../models/inventory/models/doesnt-meet-criteria-state-model";

@State<DoesntMeetCriteriaStateModel>({
  name: "DoesntMeetCriteriaState",
  defaults: {
    doesntMeetCriterias: null,
  },
})
@Injectable()
export class DoesntMeetCriteriaState {
  constructor(private inventoryService: InventoryService) {}

  @Selector()
  static getDoesntMeetCriterias({
    doesntMeetCriterias,
  }: DoesntMeetCriteriaStateModel): PageableResponse<UserProductWithProductDto> {
    return doesntMeetCriterias;
  }

  @Action(GetDoesntMeetCriterias)
  getDoesntMeetCriterias(
    { patchState }: StateContext<DoesntMeetCriteriaStateModel>,
    { payload }: GetDoesntMeetCriterias
  ) {
    return this.inventoryService
      .getDoesntMeetCriterias(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ doesntMeetCriterias: res });
        })
      );
  }

  @Action(RemoveDoesntMeetCriteria)
  removeFromCriteria(
    { patchState }: StateContext<DoesntMeetCriteriaStateModel>,
    { payload, type }: RemoveDoesntMeetCriteria
  ) {
    return this.inventoryService
      .removeDoesntMeetCriteria(
        localStorage.getItem("selectedMarketPlaceId"),
        type.toString(),
        payload.asins
      )
      .pipe(
        tap((res) => {
          patchState({ doesntMeetCriterias: res });
        })
      );
  }
}
