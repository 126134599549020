import { RouteInfo } from "./sidebar.metadata";

export var ROUTES: RouteInfo[] = [
  //main dashboard
  {
    path: "/dashboard/main",
    title: "Dashboard",
    icon: "fas fa-tachometer-alt",
    class: "",
    groupTitle: false,
    submenu: [],
  },
  {
    path: "/search/main",
    title: "Search Products",
    icon: "fas fa-search",
    class: "",
    groupTitle: false,
    submenu: [],
    disabled: false,
  },
  {
    path: "/order/main",
    title: "Orders",
    icon: "fas fa-shopping-cart",
    class: "",
    groupTitle: false,
    submenu: [],
  },

  {
    path: "/best-sellers",
    title: "Best Sellers",
    icon: "fas fa-star",
    class: "",
    groupTitle: false,
    submenu: [],
  },

  //inventory for store, new product, bulk product delete, best selling product, categories
  {
    path: "",
    title: "Inventory",
    icon: "fas fa-store",
    class: "menu-toggle",
    groupTitle: true,
    submenu: [
      {
        path: "/store/inventory",
        title: "My Store",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/store/new-product",
        title: "Add New Product",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/store/bulk-delete",
        title: "Bulk Delete Product",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/store/best-selling-product",
        title: "Best Selling Products",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/store/categories",
        title: "Categories",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Restrictions",
    icon: "fa fa-window-close",
    class: "menu-toggle",
    groupTitle: true,
    submenu: [
      {
        path: "/restrictions/category-black-list",
        title: "Categories Black List",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/restrictions/keyword-black-list",
        title: "Keywords Black List",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/restrictions/brand-black-list",
        title: "Brand Black List",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      /* {
        path: "/category/brand-pool",
        title: "Common Brand Pool",
        icon: "",
        class: "ml-menu",
        submenu: [],
      },
      {
        path: "/category/product-pool",
        title: "Common Product Pool",
        icon: "",
        class: "ml-menu",
        submenu: [],
      },*/
    ],
  },
  {
    path: "",
    title: "My Account",
    icon: "fas fa-user",
    class: "menu-toggle",
    groupTitle: true,
    submenu: [
      {
        path: "/account/profile",
        title: "My Account & My Store Info",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/preference",
        title: "My Store Preference",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/bills",
        title: "My Bills",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/notification",
        title: "My Notification",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/filter",
        title: "My Filters",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/prices",
        title: "My Price Table",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      // {
      //   path: "/account/automate-pricing-files",
      //   title: "Automate Pricing Files",
      //   icon: "",
      //   class: "ml-menu",
      //   submenu: [],
      // },
      {
        path: "/account/my-received-payments",
        title: "My Received Payments",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/my-amazon-access",
        title: "My Amazon Access",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/account/my-browser-extension",
        title: "My Browser Extension",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },

      /*{
        path: "/account/mws",
        title: "My MWS Information",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },*/
    ],
  },

  {
    path: "",
    title: "Dropy House",
    icon: "fas fa-warehouse",
    class: "menu-toggle",
    groupTitle: true,
    submenu: [
      {
        path: "/dropy/main",
        title: "Main",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/dropy/inbox",
        title: "Inbox",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/dropy/credit",
        title: "Credit",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/dropy/invoices",
        title: "Invoices",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/dropy/profile",
        title: "Profile",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/dropy/settings",
        title: "Settings",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/dropy/shippinglabel",
        title: "Shipping Label",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
    ],
  },

  {
    path: "",
    title: "Reports",
    icon: "fas fa-chart-pie",
    class: "menu-toggle",
    groupTitle: true,
    submenu: [
      {
        path: "/reports/profit-reports",
        title: "Profit Report",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/reports/sales-reports",
        title: "Sales Report",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/reports/all-market-place-reports",
        title: "All Market Place Report",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/reports/account-health",
        title: "Account Health",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
      {
        path: "/reports/negative-feedbacks",
        title: "Negative Feedbacks",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        submenu: [],
      },
    ],
  },
  // {
  //   path: "",
  //   title: "Feedback",
  //   icon: "fas fa-comment-dots",
  //   class: "menu-toggle",
  //   groupTitle: true,
  //   submenu: [
  //     {
  //       path: "/feedback/campaigns",
  //       title: "Campaigns",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/feedback/sent-emails",
  //       title: "Sent Emails",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       submenu: [],
  //     },
  //     {
  //       path: "/feedback/negative-feedbacks",
  //       title: "Negative Feedbacks",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       submenu: [],
  //     },

  //     {
  //       path: "/feedback/settings",
  //       title: "Feedback Settings",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/help/main",
    title: "Help",
    icon: "fab fa-hire-a-helper",
    class: "",
    groupTitle: false,
    submenu: [],
  },
];
