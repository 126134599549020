import { Injectable } from "@angular/core";
import {Get, Path, RestService} from "ngx-rest-service";
import { PageableResponse} from "../models";
import { Observable } from "rxjs";
import {BestSellerModel} from "../models/best-seller";

@Injectable({
  providedIn: "root",
})
export class BestSellerService extends RestService {
  @Get("best-sellers/best-sellers/{marketplaceId}/{categoryName}/{pageNumber}/{pageSize}")
  getBestSellers(
      @Path("marketplaceId") marketPlaceId: string,
      @Path("categoryName") categoryName: string,
      @Path("pageNumber") pageNumber: number,
      @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<BestSellerModel>> {
    return null;
  }
}
