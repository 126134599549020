import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  headers = new HttpHeaders().set("Content-Type", "application/json");

  constructor(private http: HttpClient, @Inject("apiUrl") private apiUrl) {}

  phoneUpdate(data) {
    let API_URL = `${this.apiUrl}account/phonenumber`;
    return this.http
      .put(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  getNotificationPreference(): Observable<any> {
    return this.http.get(`${this.apiUrl}account/notification-preferences`);
  }

  notificationUpdate(data) {
    const API_URL = `${this.apiUrl}account/notification-preferences`;
    return this.http
      .put(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  creditUpdate(data) {
    const API_URL = `${this.apiUrl}account/update-payment-details`;
    return this.http
      .put(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  getPaymentDetails(): Observable<any> {
    return this.http.get(`${this.apiUrl}account/payment-details`);
  }
  getPaidInvoices(id: string): Observable<any> {
    let endPoint: string = "get-paid-invoices";
    //if (id) endPoint += "/" + id;
    return this.http.get(`${this.apiUrl}account/${endPoint}`);
  }

  cancelSubscription(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    const API_URL = `${this.apiUrl}account/cancel-subscription`;
    return this.http
      .post(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  changePlan(id: any) {
    const API_URL = `${this.apiUrl}account/change-plan/${id}`;
    return this.http
      .put(API_URL, null, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  emailGonder(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: data,
    };
    const API_URL = `${this.apiUrl}auth/send-email-confirmation-link`;
    return this.http.post(API_URL, options).pipe(catchError(this.error));
  }

  getAccount(): Observable<any> {
    return this.http.get(`${this.apiUrl}account`);
  }

  getAccountDetails(): Observable<any> {
    return this.http.get(`${this.apiUrl}account/subscription-details`);
  }

  getInformation(marketplaceCountry): Observable<any> {
    return this.http.get(
      `${this.apiUrl}account/marketplaces/` + marketplaceCountry
    );
  }

  getPreferences(marketplaceId): Observable<any> {
    return this.http.get(
      `${this.apiUrl}account/marketplaces/` + marketplaceId + `/preferences`
    );
  }

  getOrders(data, pageNumber = 1, pageSize = 5, marketId): Observable<any> {
    //let params = JSON.stringify(data);
    let API_URL =
      `${this.apiUrl}orders/` + marketId + `/` + pageNumber + `/` + pageSize;
    return this.http
      .post(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  profileUpdate(data, marketId) {
    let params = JSON.stringify(data);
    let API_URL = `${this.apiUrl}account/marketplaces/` + marketId;
    return this.http
      .put(API_URL, params, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  accountUpdate(data) {
    let API_URL = `${this.apiUrl}account`;
    return this.http
      .put(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  preferenceUpdate(data, marketplaceId) {
    let API_URL =
      `${this.apiUrl}account/marketplaces/` + marketplaceId + `/preferences`;
    return this.http
      .put(API_URL, data, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  deleteAccount(marketplaceId) {
    let API_URL = `${this.apiUrl}account/marketplaces/` + marketplaceId;
    return this.http
      .delete(API_URL, { headers: this.headers })
      .pipe(catchError(this.error));
  }

  getStripeSetupIntentAndClientSecret(): Observable<any> {
    return this.http.get(`${this.apiUrl}account/get-setup-intent-to-change-card`);
  }

  getStripeSetupIntentAndClientSecretForDropyHouse(): Observable<any> {
    return this.http.get(`${this.apiUrl}warehouse/get-warehouse-setup-intent-to-change-card`);
  }

  error(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
