import { GetCategoryRequest } from "../models/inventory/requests/get-category";

export class GetDashboardInfo {
  static readonly type = "[GetDashboardInfo] Get Dashboard Info";

  constructor(public payload: { startDate: string; endDate: string }) {}
}
export class GetSalesSummary {
  static readonly type = "[GetSalesSummary] Get Sales Summary";
  constructor(public payload: { startDate: string; endDate: string }) {}
}
export class GetCategoriesForDashboard {
  static readonly type =
    "[GetCategoriesForDashboard] Get Categories For Dashboard";
  constructor(public payload: { startDate: string; endDate: string }) {}
}

export class GetCategories {
  static readonly type = "[GetCategories] Get Categories For Dashboard";
  constructor(public payload: GetCategoryRequest) {}
}
