<div>
  <!-- Left Sidebar -->
  <aside
    id="leftsidebar"
    class="sidebar"
    (mouseenter)="mouseHover($event)"
    (mouseleave)="mouseOut($event)"
  >
    <!-- Menu -->
    <div class="menu">
      <ul
        id="sidebarnav"
        class="list"
        [ngStyle]="{
          position: 'relative',
          'max-height': listMaxHeight + 'px',
          'max-width': listMaxWidth + 'px'
        }"
        [perfectScrollbar]
      >
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class="image">
              <img src="assets/images/DropyOnline_1000x1000.png" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name" [ngStyle]="{'filter': isUsernameBlurred ? 'blur(3px)' : 'none'}">
              {{ username }}
            </div>
            <div class="profile-usertitle-job" [ngStyle]="{'filter': isUserEmailBlurred ? 'blur(3px)' : 'none'}">
              {{ email }}
            </div>
            <div class="profile-usertitle-job" [ngStyle]="{'filter': isUserSubscriptionPackageBlurred ? 'blur(3px)' : 'none'}">
              {{ packageName }}
            </div>
          </div>
        </li>
        <!-- First level menu -->
        <li
          [class.active]="showMenu === sidebarItem.title"
          *ngFor="let sidebarItem of sidebarItems"
          [routerLinkActive]="!sidebarItem.disabled ? '' : 'active'"
        >
          <a
            [routerLink]="
              !sidebarItem.disabled && sidebarItem.class === ''
                ? [sidebarItem.path]
                : null
            "
            [routerLinkActive]="sidebarItem.disabled ? '' : 'active'"
            *ngIf="!sidebarItem.groupTitle"
            [ngClass]="[sidebarItem.class]"
            [class.disabled]="true"
            (click)="callMenuToggle($event, sidebarItem.title)"
          >
            <i [ngClass]="[sidebarItem.icon]"></i>

            <span
              *ngIf="sidebarItem.disabled && !isActiveAmazon"
              class="hide-menu"
              [matTooltip]="isActiveAmazonAlertMessage"
              >{{ sidebarItem.title }}</span
            >

            <span
              *ngIf="sidebarItem.disabled && !isActive"
              class="hide-menu"
              [matTooltip]="isActiveAlertMessage"
              >{{ sidebarItem.title }}</span
            >
            <span *ngIf="!sidebarItem.disabled" class="hide-menu">{{
              sidebarItem.title
            }}</span>
          </a>
          <a
            *ngIf="sidebarItem.groupTitle"
            [ngClass]="[sidebarItem.class]"
            (click)="callMenuToggle($event, sidebarItem.title)"
            [class.disabled]="true"
          >
            <i [ngClass]="[sidebarItem.icon]"></i>
            <span class="hide-menu">{{ sidebarItem.title }}</span>
          </a>
          <!-- Second level menu -->
          <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
            <li
              *ngFor="let sidebarSubItem of sidebarItem.submenu"
              [class.activeSub]="showSubMenu === sidebarSubItem.title"
              [routerLinkActive]="
                sidebarSubItem.submenu.length > 0 || sidebarSubItem.disabled
                  ? ''
                  : 'active'
              "
            >
              <a
                [routerLink]="
                  sidebarSubItem.submenu.length || sidebarSubItem.disabled > 0
                    ? null
                    : [sidebarSubItem.path]
                "
                (click)="callSubMenuToggle(sidebarSubItem.title)"
                [ngClass]="[sidebarSubItem.class]"
              >
                <span
                  *ngIf="sidebarSubItem.disabled && !isActiveAmazon"
                  class="hide-menu"
                  [matTooltip]="isActiveAmazonAlertMessage"
                  >{{ sidebarSubItem.title }}</span
                >
                <span
                  *ngIf="sidebarSubItem.disabled && !isActive"
                  class="hide-menu"
                  [matTooltip]="isActiveAlertMessage"
                  >{{ sidebarSubItem.title }}</span
                >
                <span *ngIf="!sidebarSubItem.disabled" class="hide-menu">{{
                  sidebarSubItem.title
                }}</span>
              </a>
              <!-- Third level menu -->
              <ul
                class="collapse"
                *ngIf="sidebarSubItem.submenu.length > 0"
                [ngClass]="{ in: showSubMenu === sidebarSubItem.title }"
              >
                <li
                  *ngFor="let sidebarSubsubItem of sidebarSubItem.submenu"
                  [ngClass]="[sidebarSubsubItem.class]"
                >
                  <a [routerLink]="[sidebarSubsubItem.path]">
                    {{ sidebarSubsubItem.title }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <!-- #Menu -->
  </aside>
  <!-- #END# Left Sidebar -->
</div>
