import { Store } from "@ngxs/store";
import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { GetAccountInformation, GetSubscriptionDetails } from "../actions";
import { mapTo, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class AccountResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve() {
    return this.store
      .dispatch([new GetAccountInformation(), new GetSubscriptionDetails()])
      .pipe(take(1), mapTo(null));
  }
}
