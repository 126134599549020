import { Store } from "@ngxs/store";
import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  GetCategoriesForDashboard,
  GetDashboardInfo,
  GetSalesSummary,
} from "../actions";
import { mapTo, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DashboardResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve() {
    const d = new Date(Date.now());
    d.setHours(23, 59, 59, 999);
    const endDate = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().slice(0, -1); 

    let date = {
      
      endDate: endDate,
      startDate: "2022-01-01T00:00:00.000Z",
    };
    return this.store
      .dispatch([
        new GetDashboardInfo(date),
        new GetSalesSummary(date),
        new GetCategoriesForDashboard(date),
      ])
      .pipe(take(1), mapTo(null));
  }
}
