import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
@Injectable({ providedIn: "root" })
export class TranslationService {
  constructor(public translateService: TranslateService) {}
  init(locale = "en") {
    this.translateService.addLangs(["en", "tr"]);
    this.translateService.use(locale);
  }
  instant(key) {
    return this.translateService.instant(key);
  }
}
