import { Injectable } from "@angular/core";
import {
  Body,
  Get,
  Path,
  Post,
  Put,
  Query,
  RestService,
} from "ngx-rest-service";
import { Observable } from "rxjs";
import {
  AvailableWarehouseResponse,
  BalanceResponse,
  CardResponse,
  CreditHistoryResponse,
  FilterResponse,
  GenericResponse,
  OpenInvoicesResponse,
  PageableResponse,
  SalesSetting,
  ShippingLabel,
  Ticket,
  WarehouseDetail,
} from "../models";
import { SaleSummaryResponse } from "../models/dashboard";
import { UserProductWithProductDto } from "../models/inventory";

@Injectable({
  providedIn: "root",
})
export class DropyHouseService extends RestService {
  @Get("warehouse/providers/available/{marketplaceId}")
  getAvailableWarehouses(
    @Path("marketplaceId") marketplaceId: string
  ): Observable<GenericResponse<AvailableWarehouseResponse[]>> {
    return null;
  }

  @Put("warehouse/activate/update-marketplace-warehouse/{marketplaceId}")
  activateWarehouse(
    @Path("marketplaceId") marketplaceId: string,
    @Body request: { warehouseId: string; warehouseCarrierId: number }
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Put("warehouse/deactivate/update-marketplace-warehouse/{marketplaceId}")
  deactivateWarehouse(
    @Path("marketplaceId") marketplaceId: string
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Get("warehouse/get-balance")
  getBalance(): Observable<GenericResponse<BalanceResponse>> {
    return null;
  }

  @Post("warehouse/add-credit")
  addCredit(
    @Body request: { amount: number }
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Get("warehouse/get-open-invoices")
  getOpenInvoices(): Observable<GenericResponse<OpenInvoicesResponse>> {
    return null;
  }

  @Get("warehouse/get-card")
  getCard(): Observable<GenericResponse<CardResponse>> {
    return null;
  }
  @Put("warehouse/change-warehouse-card/{marketplaceId}")
  changeCard(
    @Path("marketplaceId") marketplaceId: string,
    @Body request: any
  ): Observable<GenericResponse<any>> {
    return null;
  }
  @Get("warehouse/get-credit-history")
  getCreditHistory(
    @Query("startingAfterId") startingAfterId: string
  ): Observable<GenericResponse<CreditHistoryResponse[]>> {
    return null;
  }

  @Get("warehouse/get-paid-invoices")
  getPaidInvoices(
    @Query("startingAfterId") startingAfterId: string
  ): Observable<GenericResponse<OpenInvoicesResponse>> {
    return null;
  }

  @Get("warehouse/search/paid-invoices/{orderId}")
  searchPaidInvoices(
    @Path("orderId") orderId: string
  ): Observable<GenericResponse<OpenInvoicesResponse>> {
    return null;
  }

  @Get("warehouse/marketplace-warehouse-detail/{marketplaceId}")
  getWarehouseDetail(
    @Path("marketplaceId") marketplaceId: string
  ): Observable<GenericResponse<WarehouseDetail>> {
    return null;
  }

  @Put("warehouse/update-marketplace-information/{marketplaceId}")
  updateWarehouseInfo(
    @Path("marketplaceId") marketplaceId: string,
    @Body request: any
  ): Observable<GenericResponse<any>> {
    return null;
  }
  @Get("warehouse/get-shipping-labels/{marketplaceId}")
  getShippingLabels(
    @Path("marketplaceId") marketplaceId: string
  ): Observable<GenericResponse<ShippingLabel[]>> {
    return null;
  }

  @Get("warehouse/search-shipping-labels/{marketplaceId}/{searchQuery}")
  searchShippingLabels(
    @Path("marketplaceId") marketplaceId: string,
    @Path("searchQuery") searchQuery: string
  ): Observable<GenericResponse<ShippingLabel[]>> {
    return null;
  }

  @Get("warehouse/get-sales-tax-setting/{marketplaceId}")
  getSalesTaxSettings(
    @Path("marketplaceId") marketplaceId: string
  ): Observable<GenericResponse<SalesSetting>> {
    return null;
  }

  @Put("warehouse/update-warehouse-setting/{marketplaceId}")
  updateWarehouseSetting(
    @Path("marketplaceId") marketplaceId: string,
    @Body request: any
  ): Observable<GenericResponse<any>> {
    return null;
  }

  @Get("tickets/get-all/{pageNumber}/{pageSize}")
  getTickets(
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<Ticket>> {
    return null;
  }

  @Get("tickets/search/{orderNumber}/{pageNumber}/{pageSize}")
  searchTickets(
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number,
    @Path("orderNumber") orderNumber: string
  ): Observable<PageableResponse<Ticket>> {
    return null;
  }

  @Get("tickets/get-all-comments/{ticketId}")
  getTicketDetails(
    @Path("ticketId") ticketId: number
  ): Observable<PageableResponse<any>> {
    return null;
  }

  @Get("tickets/get-new-ticket-information/{orderNumber}")
  getNewTicketInfo(
    @Path("orderNumber") orderNumber: string
  ): Observable<
    GenericResponse<{
      warehouseId: string;
      warehouseName: string;
    }>
  > {
    return null;
  }
}
