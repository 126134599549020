<section class="content">
  <div class="container-fluid">
    <div class="block-header">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul class="breadcrumb breadcrumb-style">
            <li class="breadcrumb-item">
              <h4 class="page-title">Bottom Sheet</h4>
            </li>
            <li class="breadcrumb-item bcrumb-1">
              <a routerLink="/dashboard/main">
                <i class="fas fa-home"></i> Home</a
              >
            </li>
            <li class="breadcrumb-item bcrumb-2">
              <a href="#" onClick="return false;">UI</a>
            </li>
            <li class="breadcrumb-item active">Bottom Sheet</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="card">
          <div class="header">
            <h2>Bottom Sheet Overview</h2>
            <ul class="header-dropdown m-r--5">
              <li class="dropdown">
                <a
                  href="#"
                  onClick="return false;"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="material-icons">more_vert</i>
                </a>
                <ul class="dropdown-menu pull-right">
                  <li>
                    <a href="#" onClick="return false;">Action</a>
                  </li>
                  <li>
                    <a href="#" onClick="return false;">Another action</a>
                  </li>
                  <li>
                    <a href="#" onClick="return false;">Something else here</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="body">
            <p>You have received a file called "cat-picture.jpeg".</p>

            <button mat-raised-button (click)="openBottomSheet()">
              Open file
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
