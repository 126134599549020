import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { PageableResponse } from "../../models";
import { InventoryService } from "../../services/inventory.service";
import {
  GetInQueues,
  RemoveImportedToStore,
  RemoveInQueue,
} from "../../actions";
import {
  ImportedToStoreStateModel,
  UserProductWithProductDto,
} from "../../models/inventory";
import { InQueueStateModel } from "../../models/inventory";

@State<InQueueStateModel>({
  name: "InQueueState",
  defaults: {
    inQueues: null,
  },
})
@Injectable()
export class InQueueState {
  constructor(private inventoryService: InventoryService) {}

  @Selector()
  static getInQueues({
    inQueues,
  }: InQueueStateModel): PageableResponse<UserProductWithProductDto> {
    return inQueues;
  }

  @Action(GetInQueues)
  getInQueues(
    { patchState }: StateContext<InQueueStateModel>,
    { payload }: GetInQueues
  ) {
    return this.inventoryService
      .getInQueues(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ inQueues: res });
        })
      );
  }

  @Action(RemoveInQueue)
  removeInQueue(
    { patchState }: StateContext<ImportedToStoreStateModel>,
    { payload, type }: RemoveInQueue
  ) {
    return this.inventoryService.removeFromInqueue(
      localStorage.getItem("selectedMarketPlaceId"),
      type.toString(),
      payload.asins
    );
  }
}
