import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable({
    providedIn: "root",
  })
  export class CheckoutService {
    headers = new HttpHeaders().set("Content-Type", "application/json");
  
    constructor(private http: HttpClient, @Inject("apiUrl") private apiUrl) {}

    /*getPublishableKey(): Observable<any> {
        return this.http.get(`${this.apiUrl}invitation/get-stripe-publishable-key`, {
          observe: "response",
          responseType: "json",  // Change this to 'json' if the response is in JSON format
        }).pipe(catchError(this.error));
    }*/

    getPublishableKey(): Observable<string> {
        return this.http.get(`${this.apiUrl}invitation/get-stripe-publishable-key`).pipe(
          map((response: any) => {
            if (response && response.Result) {
              return response.Result;
            }
            throw new Error('Invalid response format');
          }),
          catchError(this.error)
        );
    }

    getWarehousePublishableKey(): Observable<string> {
      return this.http.get(`${this.apiUrl}warehouse/get-warehouse-stripe-publishable-key`).pipe(
        map((response: any) => {
          if (response && response.Result) {
            return response.Result;
          }
          throw new Error('Invalid response format');
        }),
        catchError(this.error)
      );
  }

    checkoutStripe(planId: string): Observable<string> {
    const API_URL = `${this.apiUrl}invitation/create-checkout-session?priceId=${planId}`;
      return this.http.post(API_URL, null, { headers: this.headers }).pipe(
          map((response: any) => {
          if (response && response.Result) {
              return response.Result;
          }
          throw new Error('Invalid response format');
          }),
          catchError(this.error)
      );
    }

    checkAndSaveSession(sessionId: string): Observable<string> {
      const API_URL = `${this.apiUrl}invitation/check-and-save-session?sessionId=${sessionId}`;
        return this.http.post(API_URL, null, { headers: this.headers }).pipe(
            map((response: any) => {
            if (response && response.Result) {
                return response.Result;
            }
            throw new Error('Invalid response format');
            }),
            catchError(this.error)
        );
    }

    handleConfirmIntent(intentId: string): Observable<string> {
      return this.http.get(`${this.apiUrl}account/handle-confirm-intent-for-change-card?intentId=${intentId}`).pipe(
        map((response: any) => {
          if (response && response.Result) {
            return response.Result;
          }
          throw new Error('Invalid response format');
        }),
        catchError(this.error)
      );
    }

    handleConfirmIntentForWarehouse(intentId: string): Observable<string> {
      return this.http.get(`${this.apiUrl}warehouse/handle-confirm-intent-for-change-card?intentId=${intentId}`).pipe(
        map((response: any) => {
          if (response && response.Result) {
            return response.Result;
          }
          throw new Error('Invalid response format');
        }),
        catchError(this.error)
      );
    }


    error(error: HttpErrorResponse) {
        let errorMessage = "";
        if (error.error instanceof ErrorEvent) {
          errorMessage = error.error.message;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }


  }