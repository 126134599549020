import { DOCUMENT } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { RightSidebarService } from "../../services/rightsidebar.service";
import { WINDOW } from "../../services/window.service";
import { Select, Store } from "@ngxs/store";
import { AccountManagementState } from "../../shared/states/account-management.state";
import { Observable } from "rxjs";
import { GenericResponse, MarketPlace } from "../../shared/models";
import { TranslationService } from "src/app/services/translate.service";
import { Router } from "@angular/router";

const document: any = window.document;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.sass"],
})
export class HeaderComponent implements OnInit {
  isNavbarShow: boolean;

  @Select(AccountManagementState.getMarketPlacesResponse)
  marketPlaces$: Observable<GenericResponse<MarketPlace[]>>;

  selectedMarketPlaceName = localStorage.getItem("selectedMarketPlaceName");
  selectedMarketPlaceCountry = localStorage.getItem(
    "selectedMarketPlaceCountry"
  );

  selectedCountry = localStorage.getItem("language") || "tr";
  isNavbarCollapsed = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private store: Store,
    private translationService: TranslationService,
    private router: Router
  ) {
    this.marketPlaces$.subscribe((d) => {
      console.log("marketPlaces$", d);
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (offset > 50) {
      this.isNavbarShow = true;
    } else {
      this.isNavbarShow = false;
    }
  }

  isPopupOpen = false;
  cbBlurUsername = false;
  cbBlurUserEmail = false;
  cbBlurUserSubscriptionPackage = false;
  cbBlurOrderBuyerInfo = false;
  cbBlurSalesOrderNumber = false;
  cbBlurASIN = false;
  cbBlurSKU = false;

  togglePopup(event: Event): void {
    event.preventDefault();
    this.isPopupOpen = !this.isPopupOpen;
  }

  saveChangesPopup(): void {
    // Save the checkbox states to localStorage
    localStorage.setItem('blurUsername', this.cbBlurUsername.toString());
    localStorage.setItem('blurUserEmail', this.cbBlurUserEmail.toString());
    localStorage.setItem('blurUserSubscriptionPackage', this.cbBlurUserSubscriptionPackage.toString());
    localStorage.setItem('blurOrderBuyerInfo', this.cbBlurOrderBuyerInfo.toString());
    localStorage.setItem('blurSalesOrderNumber', this.cbBlurSalesOrderNumber.toString());
    localStorage.setItem('blurASIN', this.cbBlurASIN.toString());
    localStorage.setItem('blurSKU', this.cbBlurSKU.toString());
    this.isPopupOpen = false;
    window.location.reload();
  }

  closePopupWithoutSave(): void {
    // Close the popup without saving any changes
    this.isPopupOpen = false;
  }

  ngOnInit() {
    this.translationService.init(this.selectedCountry);
    this.setStartupStyles();
    // Load the checkbox states from localStorage when the component initializes
    this.cbBlurUsername = localStorage.getItem('blurUsername') === 'true';
    this.cbBlurUserEmail = localStorage.getItem('blurUserEmail') === 'true';
    this.cbBlurUserSubscriptionPackage = localStorage.getItem('blurUserSubscriptionPackage') === 'true';
    this.cbBlurOrderBuyerInfo = localStorage.getItem('blurOrderBuyerInfo') === 'true';
    this.cbBlurSalesOrderNumber = localStorage.getItem('blurSalesOrderNumber') === 'true';
    this.cbBlurASIN = localStorage.getItem('blurASIN') === 'true';
    this.cbBlurSKU = localStorage.getItem('blurSKU') === 'true';
  }

  setSelectedMarketPlace(marketPlace: MarketPlace) {
    localStorage.setItem("selectedMarketPlaceId", marketPlace.marketplaceId);
    localStorage.setItem(
      "selectedMarketPlaceCountry",
      marketPlace.marketplaceCountry
    );
    localStorage.setItem(
      "selectedMarketPlaceName",
      marketPlace.marketplaceName
    );
    this.selectedMarketPlaceName = marketPlace.marketplaceName;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  setStartupStyles() {
    if (localStorage.getItem("theme")) {
      this.renderer.removeClass(this.document.body, "dark");
      this.renderer.removeClass(this.document.body, "light");
      this.renderer.addClass(this.document.body, localStorage.getItem("theme"));
    } else {
      this.renderer.addClass(this.document.body, "light");
    }

    // set light sidebar menu on startup
    if (localStorage.getItem("menu_option")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("menu_option")
      );
    } else {
      this.renderer.addClass(this.document.body, "menu_light");
    }

    // set logo color on startup
    if (localStorage.getItem("choose_logoheader")) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem("choose_logoheader")
      );
    } else {
      this.renderer.addClass(this.document.body, "logo-white");
    }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains("side-closed");
    if (hasClass) {
      this.renderer.removeClass(this.document.body, "side-closed");
      this.renderer.removeClass(this.document.body, "submenu-closed");
    } else {
      this.renderer.addClass(this.document.body, "side-closed");
      this.renderer.addClass(this.document.body, "submenu-closed");
    }
  }

  selectCountry(lang) {
    this.selectedCountry = lang;
    localStorage.setItem("language", this.selectedCountry);
    this.translationService.init(this.selectedCountry);
    this.window.location.reload();
  }

  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar =
        !this.dataService.currentStatus._isScalar)
    );
  }

  clearCache() {
    localStorage.clear();
  }

  clearCacheAndRedirect() {
    const lang = this.getLocalStorageLang();
    localStorage.clear();
    this.router.navigate(['/authentication/signin'], { queryParams: { lang: lang } });
  }
  
  getLocalStorageLang(): string {
    return localStorage.getItem('language') || 'tr';
  }
}
