import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { Injectable } from "@angular/core";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";
import snq from "snq";
import { AuthService } from "../services";
import { Auth } from "../models";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService, private snackBar: MatSnackBar, private router: Router) {}

  showNotification(colorName, text, placementFrom, placementAlign) {
    this.snackBar.open(text, "", {
      duration: 5000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }
  
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            return this.handle401Error(request, next);
          } else if (err.status === 400) {
            const error = snq(
              () => err.error.Result.errors.map((data) => data.message),
              "Something went wrong"
            );
            if (error) {
              this.showNotification("snackbar-danger", error, "bottom", "center");
            }
          } else if (err.status === 500) {
            const error = err.error.ErrorMessage;
            if (error) {
              this.showNotification("snackbar-danger", error, "bottom", "center");
            }
          } else {
            return throwError(err);
          }
        } else {
          // Handle custom error format
          if (err.status === 401 || 
                (err.StatusCode && err.StatusCode === 401) ||
                    (typeof err === 'string' && err.toLowerCase().includes('failed to refresh tokens'))) {
            return this.handle401Error(request, next);
          } else {
            return throwError(err);
          }
        }
        
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = localStorage.getItem("refreshToken");

      if (!refreshToken) {
        this.router.navigateByUrl("/authentication/signin");
        return throwError("Refresh token missing.");
      }

      return this.refreshTokens(refreshToken).pipe(
        switchMap((response) => {
          // Check if response is of type RefreshTokenResponse
          if (response.Result.hasOwnProperty("accessToken")) {
            const refreshTokenResponse = response.Result as Auth.RefreshTokenResponse;
            // If new tokens are obtained, update localStorage
            localStorage.setItem("accessToken", refreshTokenResponse.accessToken);
            localStorage.setItem("refreshToken", refreshTokenResponse.refreshToken);

            this.isRefreshing = false;
            this.refreshTokenSubject.next(refreshTokenResponse.accessToken);
            // Retry the original request with the new tokens
            return next.handle(this.addToken(request));
          } else {
            // Handle generic response if necessary
            this.isRefreshing = false;
            // Redirect to sign-in page
            this.router.navigateByUrl("/authentication/signin");
            return throwError("Unexpected response received.");
          }
        }),
        catchError((error) => {
          // If failed to refresh tokens, redirect to sign-in
          this.isRefreshing = false;
          this.router.navigateByUrl("/authentication/signin");
          return throwError("Failed to refresh tokens.");
        })
      );

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request));
        })
      );
    }
  
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }
    return request;
  }

  private refreshTokens(
    refreshToken: string
  ): Observable<any> {
    return this.authService
      .refreshToken({ accessToken: localStorage.getItem("accessToken"), refreshToken })
      .pipe(catchError((error) => throwError(error)));
  }

  private refreshPage() {
    this.router.navigateByUrl(this.router.url);
  }
}
