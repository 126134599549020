import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { PageableResponse } from "../../models";
import {
  UserProductWithProductDto,
  ImportedToStoreStateModel,
} from "../../models/inventory";
import { InventoryService } from "../../services/inventory.service";
import {
  AdvancedSearchImportedStore,
  GetImportedToStores,
  RemoveAdvancedSearchImportedStore,
  RemoveImportedToStore,
} from "../../actions";

@State<ImportedToStoreStateModel>({
  name: "ImportedToStoreState",
  defaults: {
    importedToStores: null,
  },
})
@Injectable()
export class ImportedToStoreState {
  constructor(private inventoryService: InventoryService) {}

  @Selector()
  static getImportedToStores({
    importedToStores,
  }: ImportedToStoreStateModel): PageableResponse<UserProductWithProductDto> {
    return importedToStores;
  }

  @Action(GetImportedToStores)
  getImportedToStores(
    { patchState }: StateContext<ImportedToStoreStateModel>,
    { payload }: GetImportedToStores
  ) {
    return this.inventoryService
      .getImportedToStores(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          localStorage.setItem("isAdvancedImportedStore", "0");
          patchState({ importedToStores: res });
        })
      );
  }

  @Action(AdvancedSearchImportedStore)
  advancedSearchImportedStore(
    { patchState }: StateContext<ImportedToStoreStateModel>,
    { payload }: AdvancedSearchImportedStore
  ) {
    return this.inventoryService
      .advancedSearchImportedStore(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize,
        payload.data
      )
      .pipe(
        tap((res) => {
          localStorage.setItem("isAdvancedImportedStore", "1");
          patchState({ importedToStores: res });
        })
      );
  }

  @Action(RemoveAdvancedSearchImportedStore)
  removeAdvancedSearchImportedStore(
    { patchState }: StateContext<ImportedToStoreStateModel>,
    { payload }: RemoveAdvancedSearchImportedStore
  ) {
    return this.inventoryService
      .removeAdvancedSearchImportedStore(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.data
      )
      .pipe(
        tap((res) => {
        })
      );
  }

  @Action(RemoveImportedToStore)
  removeImportedToStore(
    { patchState }: StateContext<ImportedToStoreStateModel>,
    { payload, type }: RemoveImportedToStore
  ) {
    return this.inventoryService.removeImportedToStore(
      localStorage.getItem("selectedMarketPlaceId"),
      type.toString(),
      payload.asins
    );
  }
}
