export class GetAvailableWareHouses {
  static readonly type = "[dropyhouse] Get Available Warehouses";
}

export class ActivateWarehouse {
  static readonly type = "[dropyhouse] Activate Warehouse";

  constructor(
    public readonly payload: { warehouseId: string; warehouseCarrierId: number }
  ) {}
}

export class DeactivateWarehouse {
  static readonly type = "[dropyhouse] Deactivate Warehouse";
}

export class GetBalance {
  static readonly type = "[dropyhouse] GetBalance";
}
export class AddCredit {
  static readonly type = "[dropyhouse] AddCredit";
  constructor(public readonly payload: { amount: number }) {}
}
export class GetOpenInvoices {
  static readonly type = "[dropyhouse] GetOpenInvoices";
}
export class GetCard {
  static readonly type = "[dropyhouse] GetCard";
}
export class ChangeCard {
  static readonly type = "[dropyhouse] ChangeCard";

  constructor(public readonly payload: any) {}
}

export class GetCreditHistory {
  static readonly type = "[dropyhouse] GetCreditHistory";

  constructor(public readonly payload: string) {}
}

export class GetPaidInvoices {
  static readonly type = "[dropyhouse] GetPaidInvoices";

  constructor(public readonly payload: string) {}
}

export class SearchPaidInvoices {
  static readonly type = "[dropyhouse] SearchPaidInvoices";

  constructor(public readonly payload: string) {}
}
export class GetWarehouseDetail {
  static readonly type = "[dropyhouse] GetWarehouseDetail";
}

export class UpdateWarehouseInfo {
  static readonly type = "[dropyhouse] UpdateWarehouseInfo";

  constructor(public readonly payload: any) {}
}
export class GetShippingLabels {
  static readonly type = "[dropyhouse] GetShippingLabels";
}
export class SearchShippingLabels {
  static readonly type = "[dropyhouse] SearchShippingLabels";

  constructor(public readonly payload: string) {}
}
export class GetSalesSettings {
  static readonly type = "[dropyhouse] GetSalesSettings";
}
export class UpdateWarehouseSetting {
  static readonly type = "[dropyhouse] UpdateWarehouseSetting";

  constructor(public readonly payload: any) {}
}
export class GetTickets {
  static readonly type = "[dropyhouse] GetTickets";

  constructor(public readonly page: number, public readonly size: number) {}
}

export class SearchTickets {
  static readonly type = "[dropyhouse] SearchTickets";

  constructor(
    public readonly page: number,
    public readonly size: number,
    public readonly orderId: string
  ) {}
}
export class GetTicketDetails {
  static readonly type = "[dropyhouse] GetTicketDetails";

  constructor(public readonly payload: number) {}
}
export class GetNewTicketInfo {
  static readonly type = "[dropyhouse] GetNewTicketInfo";

  constructor(public readonly payload: string) {}
}
