import { Store } from "@ngxs/store";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  GetAvailableWareHouses,
  GetNewTicketInfo,
  GetTicketDetails,
  GetTickets,
} from "../actions";
import { mapTo, take } from "rxjs/operators";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DropyHouseNewMessageResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot) {
    return route?.queryParams?.orderNumber
      ? this.store
          .dispatch([new GetNewTicketInfo(route.queryParams.orderNumber)])
          .pipe(take(1), mapTo(null))
      : of(null);
  }
}
