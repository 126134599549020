import { ProductInventoryStateModel } from "../models";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { ProductInventoryService } from "../services";
import { tap } from "rxjs/operators";
import { GetBestSellerCategories } from "../actions/best-seller.actions";
import {
  GetCategoriesExcel,
  GetOrderDetails,
  InquireProductDetail,
} from "../actions";
import { ProductDetailService } from "../services/product-detail.service";
import { InventoryService } from "../services/inventory.service";

@State<ProductInventoryStateModel>({
  name: "ProductInventoryState",
  defaults: {
    bestSellerCategories: [],
    productDetail: [],
    orderDetail: null,
  },
})
@Injectable()
export class ProductInventoryState {
  constructor(
    private productInventoryService: ProductInventoryService,
    private productDetailService: ProductDetailService,
    private inventoryService: InventoryService
  ) {}

  @Selector()
  static getBestSellerCategories({
    bestSellerCategories,
  }: ProductInventoryStateModel): string[] {
    return bestSellerCategories;
  }

  @Selector()
  static getProductDetails({
    productDetail,
  }: ProductInventoryStateModel): string[] {
    return productDetail;
  }

  @Selector()
  static getOrderDetails({ orderDetail }: ProductInventoryStateModel): any {
    return orderDetail;
  }

  @Selector()
  static getCategoriesExcel({ excel }): any {
    return excel;
  }

  @Action(GetBestSellerCategories)
  getBestSellerCategories({
    patchState,
  }: StateContext<ProductInventoryStateModel>) {
    const countryCode = localStorage.getItem("selectedMarketPlaceCountry");
    return this.productInventoryService.getBestSellerCategories(countryCode).pipe(
      tap((res) => {
        patchState({ bestSellerCategories: res.Result });
      })
    );
  }

  @Action(InquireProductDetail)
  inquireProductDetail(
    { patchState }: StateContext<ProductInventoryStateModel>,
    { payload }: InquireProductDetail
  ) {
    const countryCode = localStorage.getItem("selectedMarketPlaceCountry");
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.productDetailService
      .getProductDetail({ marketplaceId, countryCode, asin: payload })
      .pipe(
        tap((res) => {
          patchState({ productDetail: res.Result });
        })
      );
  }

  @Action(GetOrderDetails)
  getOrderDetails(
    { patchState }: StateContext<ProductInventoryStateModel>,
    { payload }: GetOrderDetails
  ) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.inventoryService.getOrderDetails(marketplaceId, payload).pipe(
      tap((res) => {
        patchState({ orderDetail: res.Result });
      })
    );
  }

  @Action(GetCategoriesExcel)
  getCategoriesExcel({ patchState }: StateContext<any>) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.inventoryService.getCategoriesExcel(marketplaceId).pipe(
      tap((res) => {
        patchState({ excel: res.Result });
      })
    );
  }
}
