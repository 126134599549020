import {BestSellersRequest} from "../models/best-seller";

export class GetBestSellers {
  static readonly type = "[BestSeller] Get Best Seller Products";
  constructor(public readonly payload: BestSellersRequest) {}
}

export class GetBestSellerCategories {
  static readonly type = "[ProductInventory] Get Best Seller Categories";
}
