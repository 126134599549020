import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {BestSellerService} from "../services/best-seller.service";
import {BestSellerModel, BestSellerStateModel} from "../models/best-seller";
import {GetBestSellers} from "../actions/best-seller.actions";
import {PageableResponse} from "../models";

@State<BestSellerStateModel>({
  name: "BestSellerState",
  defaults: {
    bestSellers: null
  },
})
@Injectable()
export class BestSellerState {
  constructor(private bestSellerService: BestSellerService) {}

  @Selector()
  static getBestSellers({bestSellers
  }: BestSellerStateModel): PageableResponse<BestSellerModel> {
    return bestSellers;
  }

  @Action(GetBestSellers)
  getBestSellers(
      { patchState }: StateContext<BestSellerStateModel>,
      { payload }: GetBestSellers) {
    return this.bestSellerService.getBestSellers(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.categoryName,
        payload.pageNumber,
        payload.pageSize
    ).pipe(
      tap((res) => {
        patchState({ bestSellers: res });
      })
    );
  }
}
