import {
  BrandBlackList,
  CategoryBlackList,
  GenericResponse,
  KeywordBlackListResponse,
  PageableResponse,
  RestrictionStateModel,
} from "../models";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { RestrictionService } from "../services";
import {
  CreateAllowedBrandBlackList,
  CreateCategoryBlack,
  CreateKeywordBlackList,
  CreateRestrictedBrandBlackList,
  GetAllowedBrandBlackList,
  GetCategoryBlackListWithPage,
  GetKeywordBlackListByMarketPlaceId,
  GetRestrictedBrandBlackList,
  SearchAllowedBrandBlackList,
  SearchCategoryBlackListWithPage,
  SearchKeywordBlackList,
  SearchRestrictedBrandBlackList,
} from "../actions";
import { tap } from "rxjs/operators";

@State<RestrictionStateModel>({
  name: "RestrictionState",
  defaults: {
    keywordBlackListResponse: null,
    createKeywordBlackListResponse: null,
    allowedBrandList: null,
    restrictedBrandList: null,
    createAllowedBrandBlackListResponse: null,
    createRestrictedBrandBlackListResponse: null,
    categoryBlackListWithPage: null,
    createCategoryBlackListResponse: null,
  },
})
@Injectable()
export class RestrictionState {
  constructor(private restrictionService: RestrictionService) {}

  @Selector()
  static getKeywordBlackListResponse({
    keywordBlackListResponse,
  }: RestrictionStateModel): PageableResponse<KeywordBlackListResponse[]> {
    return keywordBlackListResponse;
  }

  @Selector()
  static getCreateKeywordBlackListResponse({
    createKeywordBlackListResponse,
  }: RestrictionStateModel): GenericResponse<null> {
    return createKeywordBlackListResponse;
  }

  @Selector()
  static getRestrictedBrandBlackList({
    restrictedBrandList,
  }: RestrictionStateModel): PageableResponse<BrandBlackList[]> {
    return restrictedBrandList;
  }

  @Selector()
  static getAllowedBrandBlackList({
    allowedBrandList,
  }: RestrictionStateModel): PageableResponse<BrandBlackList[]> {
    return allowedBrandList;
  }

  @Selector()
  static getCreateRestrictedBrandBlackListResponse({
    createRestrictedBrandBlackListResponse,
  }: RestrictionStateModel): GenericResponse<null> {
    return createRestrictedBrandBlackListResponse;
  }

  @Selector()
  static getCreateAllowedBrandBlackListResponse({
    createAllowedBrandBlackListResponse,
  }: RestrictionStateModel): GenericResponse<null> {
    return createAllowedBrandBlackListResponse;
  }

  @Selector()
  static getCreateCategoryBlackListResponse({
    createCategoryBlackListResponse,
  }: RestrictionStateModel): GenericResponse<null> {
    return createCategoryBlackListResponse;
  }

  @Selector()
  static getCategoryBlackListWithPage({
    categoryBlackListWithPage,
  }: RestrictionStateModel): PageableResponse<CategoryBlackList> {
    return categoryBlackListWithPage;
  }

  @Action(GetKeywordBlackListByMarketPlaceId)
  getKeywordBlackListByMarketPlaceId(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: GetKeywordBlackListByMarketPlaceId
  ) {
    return this.restrictionService
      .getKeywordBlackListByMarketPlaceId(
        payload.marketplaceId,
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ keywordBlackListResponse: res });
        })
      );
  }

  @Action(SearchKeywordBlackList)
  searchKeywordBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: SearchKeywordBlackList
  ) {
    return this.restrictionService
      .searchKeywordBlackListByMarketPlaceId(
        payload.marketplaceId,
        payload.pageNumber,
        payload.pageSize,
        payload.keywordName
      )
      .pipe(
        tap((res) => {
          patchState({ keywordBlackListResponse: res });
        })
      );
  }

  @Action(CreateKeywordBlackList)
  CreateKeywordBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: CreateKeywordBlackList
  ) {
    return this.restrictionService.createKeywordBlackList(payload).pipe(
      tap((res) => {
        patchState({ createKeywordBlackListResponse: res });
      })
    );
  }

  @Action(GetAllowedBrandBlackList)
  getAllowedBrandBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: GetAllowedBrandBlackList
  ) {
    return this.restrictionService
      .getAllowedBrandBlackList(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ allowedBrandList: res });
        })
      );
  }

  @Action(SearchAllowedBrandBlackList)
  searchAllowedBrandBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: SearchAllowedBrandBlackList
  ) {
    return this.restrictionService
      .searchAllowedBrandBlackList(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize,
        payload.brandName
      )
      .pipe(
        tap((res) => {
          patchState({ allowedBrandList: res });
        })
      );
  }

  @Action(GetRestrictedBrandBlackList)
  getRestrictedBrandBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: GetRestrictedBrandBlackList
  ) {
    return this.restrictionService
      .getRestrictedBrandBlackList(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ restrictedBrandList: res });
        })
      );
  }

  @Action(SearchRestrictedBrandBlackList)
  searchRestrictedBrandBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: SearchRestrictedBrandBlackList
  ) {
    return this.restrictionService
      .searchRestrictedBrandBlackList(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize,
        payload.brandName
      )
      .pipe(
        tap((res) => {
          patchState({ restrictedBrandList: res });
        })
      );
  }

  @Action(CreateRestrictedBrandBlackList)
  createRestrictedBrandBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: CreateRestrictedBrandBlackList
  ) {
    return this.restrictionService.createRestrictedBrandBlackList(payload).pipe(
      tap((res) => {
        patchState({ createRestrictedBrandBlackListResponse: res });
      })
    );
  }

  @Action(CreateAllowedBrandBlackList)
  createAllowedBrandBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: CreateAllowedBrandBlackList
  ) {
    return this.restrictionService.createAllowedBrandBlackList(payload).pipe(
      tap((res) => {
        patchState({ createAllowedBrandBlackListResponse: res });
      })
    );
  }

  @Action(GetCategoryBlackListWithPage)
  getCategoryBlackListWithPage(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: GetCategoryBlackListWithPage
  ) {
    return this.restrictionService
      .getCategoryBlackList(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ categoryBlackListWithPage: res });
        })
      );
  }

  @Action(SearchCategoryBlackListWithPage)
  searchCategoryBlackListWithPage(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: SearchCategoryBlackListWithPage
  ) {
    return this.restrictionService
      .searchCategoryBlackList(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize,
        payload.categoryName
      )
      .pipe(
        tap((res) => {
          patchState({ categoryBlackListWithPage: res });
        })
      );
  }

  @Action(CreateCategoryBlack)
  createCategoryBlackList(
    { patchState }: StateContext<RestrictionStateModel>,
    { payload }: CreateCategoryBlack
  ) {
    return this.restrictionService.createCategoryBlackList(payload).pipe(
      tap((res) => {
        patchState({ createCategoryBlackListResponse: res });
      })
    );
  }
}
