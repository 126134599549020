import {
  AvailableWarehouseResponse,
  BalanceResponse,
  CardResponse,
  CreditHistoryResponse,
  DropyHouseStateModel,
  OpenInvoicesResponse,
  PageableResponse,
  SalesSetting,
  ShippingLabel,
  Ticket,
  WarehouseDetail,
} from "../models";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { DropyHouseService } from "../services";
import {
  ActivateWarehouse,
  AddCredit,
  ChangeCard,
  DeactivateWarehouse,
  GetAvailableWareHouses,
  GetBalance,
  GetCard,
  GetCreditHistory,
  GetNewTicketInfo,
  GetOpenInvoices,
  GetPaidInvoices,
  GetSalesSettings,
  GetShippingLabels,
  GetTicketDetails,
  GetTickets,
  GetWarehouseDetail,
  SearchPaidInvoices,
  SearchShippingLabels,
  SearchTickets,
  UpdateWarehouseInfo,
  UpdateWarehouseSetting,
} from "../actions";
import { tap } from "rxjs/operators";

@State<DropyHouseStateModel>({
  name: "DropyHouseState",
  defaults: {
    availableWarehouses: [],
    balanceInfo: null,
    openInvoceInfo: null,
    cardInfo: null,
    creditHistory: [],
    paidInvoiceInfo: null,
    warehouseDetail: null,
    shippingLabels: [],
    salesSetting: null,
    tickets: null,
    ticketDetail: null,
    newTicketInfo: null,
  },
})
@Injectable()
export class DropyHouseState {
  constructor(private dropyHouseService: DropyHouseService) {}

  @Selector()
  static availableWarehouses({
    availableWarehouses,
  }: DropyHouseStateModel): AvailableWarehouseResponse[] {
    return availableWarehouses;
  }

  @Selector()
  static openInvoceInfo({
    openInvoceInfo,
  }: DropyHouseStateModel): OpenInvoicesResponse {
    return openInvoceInfo;
  }

  @Selector()
  static balanceInfo({ balanceInfo }: DropyHouseStateModel): BalanceResponse {
    return balanceInfo;
  }

  @Selector()
  static cardInfo({ cardInfo }: DropyHouseStateModel): CardResponse {
    return cardInfo;
  }

  @Selector()
  static paidInvoiceInfo({
    paidInvoiceInfo,
  }: DropyHouseStateModel): OpenInvoicesResponse {
    return paidInvoiceInfo;
  }

  @Selector()
  static creditHistory({
    creditHistory,
  }: DropyHouseStateModel): CreditHistoryResponse[] {
    return creditHistory;
  }

  @Selector()
  static warehouseDetail({
    warehouseDetail,
  }: DropyHouseStateModel): WarehouseDetail {
    return warehouseDetail;
  }

  @Selector()
  static shippingLabels({
    shippingLabels,
  }: DropyHouseStateModel): ShippingLabel[] {
    return shippingLabels;
  }

  @Selector()
  static salesSetting({ salesSetting }: DropyHouseStateModel): SalesSetting {
    return salesSetting;
  }

  @Selector()
  static tickets({ tickets }: DropyHouseStateModel): PageableResponse<Ticket> {
    return tickets;
  }

  @Selector()
  static ticketDetail({ ticketDetail }: DropyHouseStateModel): any {
    return ticketDetail;
  }

  @Selector()
  static newTicketInfo({ newTicketInfo }: DropyHouseStateModel): any {
    return newTicketInfo;
  }

  @Action(GetAvailableWareHouses)
  getAvailableWareHouses({ patchState }: StateContext<DropyHouseStateModel>) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService.getAvailableWarehouses(marketplaceId).pipe(
      tap((res) => {
        patchState({ availableWarehouses: res.Result });
      })
    );
  }

  @Action(ActivateWarehouse)
  activateWarehouse(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: ActivateWarehouse
  ) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService
      .activateWarehouse(marketplaceId, payload)
      .pipe();
  }

  @Action(DeactivateWarehouse)
  deactivateWarehouse({ patchState }: StateContext<DropyHouseStateModel>) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService.deactivateWarehouse(marketplaceId).pipe();
  }

  @Action(GetBalance)
  getBalance({ patchState }: StateContext<DropyHouseStateModel>) {
    return this.dropyHouseService.getBalance().pipe(
      tap((res) => {
        patchState({ balanceInfo: res?.Result });
      })
    );
  }

  @Action(AddCredit)
  addCredit(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: AddCredit
  ) {
    return this.dropyHouseService.addCredit(payload).pipe();
  }

  @Action(GetOpenInvoices)
  getOpenInvoices({ patchState }: StateContext<DropyHouseStateModel>) {
    return this.dropyHouseService.getOpenInvoices().pipe(
      tap((res) => {
        patchState({ openInvoceInfo: res?.Result });
      })
    );
  }

  @Action(GetCard)
  getCard({ patchState }: StateContext<DropyHouseStateModel>) {
    return this.dropyHouseService.getCard().pipe(
      tap((res) => {
        patchState({ cardInfo: res?.Result });
      })
    );
  }

  @Action(ChangeCard)
  changeCard(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: ChangeCard
  ) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService.changeCard(marketplaceId, payload).pipe();
  }

  @Action(GetCreditHistory)
  getCreditHistory(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: GetCreditHistory
  ) {
    return this.dropyHouseService.getCreditHistory(payload).pipe(
      tap((res) => {
        patchState({ creditHistory: res?.Result });
      })
    );
  }

  @Action(GetPaidInvoices)
  getPaidInvoices(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: GetPaidInvoices
  ) {
    return this.dropyHouseService.getPaidInvoices(payload).pipe(
      tap((res) => {
        patchState({ paidInvoiceInfo: res?.Result });
      })
    );
  }

  @Action(SearchPaidInvoices)
  searchPaidInvoices(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: SearchPaidInvoices
  ) {
    return this.dropyHouseService.searchPaidInvoices(payload).pipe(
      tap((res) => {
        patchState({ paidInvoiceInfo: res?.Result });
      })
    );
  }

  @Action(GetWarehouseDetail)
  getWarehouseDetail({ patchState }: StateContext<DropyHouseStateModel>) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService.getWarehouseDetail(marketplaceId).pipe(
      tap((res) => {
        patchState({ warehouseDetail: res?.Result });
      })
    );
  }

  @Action(UpdateWarehouseInfo)
  updateWarehouseInfo(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: UpdateWarehouseInfo
  ) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService
      .updateWarehouseInfo(marketplaceId, payload)
      .pipe();
  }

  @Action(GetShippingLabels)
  getShippingLabels({ patchState }: StateContext<DropyHouseStateModel>) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService.getShippingLabels(marketplaceId).pipe(
      tap((res) => {
        patchState({ shippingLabels: res?.Result });
      })
    );
  }

  @Action(SearchShippingLabels)
  searchShippingLabels(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: SearchShippingLabels
  ) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService
      .searchShippingLabels(marketplaceId, payload)
      .pipe(
        tap((res) => {
          patchState({ shippingLabels: res?.Result });
        })
      );
  }

  @Action(GetSalesSettings)
  getSalesSettings({ patchState }: StateContext<DropyHouseStateModel>) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService.getSalesTaxSettings(marketplaceId).pipe(
      tap((res) => {
        patchState({ salesSetting: res?.Result });
      })
    );
  }

  @Action(UpdateWarehouseSetting)
  updateWarehouseSetting(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: UpdateWarehouseSetting
  ) {
    const marketplaceId = localStorage.getItem("selectedMarketPlaceId");
    return this.dropyHouseService
      .updateWarehouseSetting(marketplaceId, payload)
      .pipe();
  }

  @Action(GetTickets)
  getTickets(
    { patchState }: StateContext<DropyHouseStateModel>,
    { size, page }: GetTickets
  ) {
    return this.dropyHouseService.getTickets(page, size).pipe(
      tap((res) => {
        patchState({ tickets: res });
      })
    );
  }

  @Action(SearchTickets)
  searchTickets(
    { patchState }: StateContext<DropyHouseStateModel>,
    { size, page, orderId }: SearchTickets
  ) {
    return this.dropyHouseService.searchTickets(page, size, orderId).pipe(
      tap((res) => {
        patchState({ tickets: res });
      })
    );
  }

  @Action(GetTicketDetails)
  getTicketDetails(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: GetTicketDetails
  ) {
    return this.dropyHouseService.getTicketDetails(payload).pipe(
      tap((res) => {
        patchState({ ticketDetail: res?.Result });
      })
    );
  }

  @Action(GetNewTicketInfo)
  getNewTicketInfo(
    { patchState }: StateContext<DropyHouseStateModel>,
    { payload }: GetNewTicketInfo
  ) {
    return this.dropyHouseService.getNewTicketInfo(payload).pipe(
      tap((res) => {
        patchState({ newTicketInfo: res?.Result });
      })
    );
  }
}
