import { Injectable } from "@angular/core";
import { Body, Get, Path, Post, RestService } from "ngx-rest-service";
import { Observable } from "rxjs";
import { GenericResponse, PageableResponse } from "../models";
import {
  CategoryDashboard,
  DashboadInfo,
  SaleSummaryResponse,
} from "../models/dashboard";

@Injectable({
  providedIn: "root",
})
export class DashboardService extends RestService {
  @Post("dashboard/{marketplaceId}")
  getDashboardInfo(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: { startDate: string; endDate: string }
  ): Observable<GenericResponse<DashboadInfo>> {
    return null;
  }

  @Get("dashboard/sales-summary/{marketplaceId}")
  getSalesSummary(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: { startDate: string; endDate: string }
  ): Observable<GenericResponse<SaleSummaryResponse[]>> {
    return null;
  }

  @Get("inventory/categories/{marketplaceId}/1/10")
  getCategoriesForDashboard(
    @Path("marketplaceId") marketPlaceId: string,
    @Body request: { startDate: string; endDate: string }
  ): Observable<PageableResponse<CategoryDashboard[]>> {
    return null;
  }

  @Get("inventory/categories/{marketplaceId}/{pageNumber}/{pageSize}")
  getCategories(
    @Path("marketplaceId") marketPlaceId: string,
    @Path("pageNumber") pageNumber: number,
    @Path("pageSize") pageSize: number
  ): Observable<PageableResponse<CategoryDashboard>> {
    return null;
  }
}
