import { Injectable } from "@angular/core";
import { isAfter } from "date-fns";
import decode from "jwt-decode";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import snq from "snq";
import { Select } from "@ngxs/store";
import { AccountManagementState } from "../states";
import { GenericResponse, MarketPlace } from "../models";

@Injectable()
export class isActiveAccountGuard implements CanActivate {
  @Select(AccountManagementState.getMarketPlacesResponse)
  marketPlaces$: Observable<GenericResponse<MarketPlace[]>>;

  constructor(private router: Router) {}

  findTargetIds(data, id) {
    if (!data || !id) return null;
    return data.find((marketplace: any) => {
      if (marketplace.marketplaceId == id) return true;
    });
  }

  /**
   * return activate account from token
   *
   * @param {string} encodedToken - base 64 token received from server and stored in local storage
   * @returns {date | null} returns activate account or null id expired props not found in decoded token
   */
  getActiveAccount(encodedToken: any): boolean {
    const daysLeft = this.getTokenDaysLeft(encodedToken);
    const rightNow = new Date();
    const isActiveAccount = isAfter(rightNow, daysLeft);
    return isActiveAccount;
  }
  /**
   * return left date from token
   *
   * @param {string} encodedToken - base 64 token received from server and stored in local storage
   * @returns {date | null} returns days left or null id expired props not found in decoded token
   */
  getTokenDaysLeft(encodedToken: any): Date {
    if (!encodedToken) {
      return new Date(0); // is expired
    }

    const token: any = decode(encodedToken);
    if (token.DAYS_LEFT) {
      const daysLeft = snq(() => localStorage.getItem("daysLeft"));
      if (daysLeft) {
        token.DAYS_LEFT = daysLeft;
      } else {
        return new Date(0); // is expired
      }
    }
    const daysLeft = new Date(token.DAYS_LEFT);
    return daysLeft;
  }

  canActivate(
    next: ActivatedRouteSnapshot | string,
    state: RouterStateSnapshot | string
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      if (this.getActiveAccount(snq(() => localStorage.getItem("accessToken")))) {
        localStorage.setItem("active_account", "deactive");
        resolve(false);
      } else {
        localStorage.setItem("active_account", "active");
        resolve(true);
      }
    });
  }
}
