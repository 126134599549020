import { Auth } from "../models";

export class AuthLogin {
  static readonly type = "[Auth] auth login";

  constructor(public payload: Auth.LoginRequest) {}
}

export class ForgotPassword {
  static readonly type = "[Auth] Forgot Password";

  constructor(public payload: Auth.ForgotPasswordRequest) {}
}

export class Register {
  static readonly type = "[Auth] Register";

  constructor(public payload: Auth.RegisterRequest) {}
}

export class EmailConfirm {
  static readonly type = "[Auth] Email Confirm";

  constructor(public payload: Auth.EmailConfirmRequest) {}
}
export class SendEmailConfirmationLink {
  static readonly type = "[Auth] SendEmailConfirmationLink";

  constructor(public payload: string) {}
}

export class ResetPassword {
  static readonly type = "[Auth] ResetPassword";

  constructor(
    public payload: {
      email: string;
      password: string;
      resetPasswordToken: string;
    }
  ) {}
}
export class EmailConfirmLink {
  static readonly type = "[Auth] EmailConfirmLink";

  constructor(public payload: { email: string; confirmationToken: string }) {}
}
export class GetAvailablePackages {
  static readonly type = "[Auth] GetAvailablePackages";
}
export class CheckDiscountCode {
  static readonly type = "[Auth] CheckDiscountCode";
  constructor(public payload: any) {}
}
export class PackageSelection {
  static readonly type = "[Auth] PackageSelection";
  constructor(public payload: Auth.NewPackageSelectionRequest) {}
}
export class AvailableMarketplaces {
  static readonly type = "[Auth] AvailableMarketplaces";
  constructor(public payload: string) {}
}

export class SaveMarketplaceInfo {
  static readonly type = "[Auth] SaveMarketplaceInfo";
  constructor(public payload: any) {}
}
export class ExchangeAmazonCode {
  static readonly type = "[Auth] ExchangeAmazonCode";
  constructor(public payload: string) {}
}
export class GetAddressCoupon {
  static readonly type = "[Auth] GetAddressCoupon";
  constructor(public payload: string) {}
}
