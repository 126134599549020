import { Store } from "@ngxs/store";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import {
  GetAvailableWareHouses,
  GetTicketDetails,
  GetTickets,
} from "../actions";
import { mapTo, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DropyHouseInboxDetailResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot) {
    return this.store
      .dispatch([new GetTicketDetails(route.queryParams.id)])
      .pipe(take(1), mapTo(null));
  }
}
