import { Injectable } from "@angular/core";
import { Body, Get, Path, Post, Query, RestService } from "ngx-rest-service";
import { Auth, GenericResponse } from "../models";
import { Observable } from "rxjs";
import ForgotPasswordResponse = Auth.ForgotPasswordResponse;

@Injectable({
  providedIn: "root",
})
export class AuthService extends RestService {
  @Post("auth/login")
  login(
    @Body loginRequest: Auth.LoginRequest
  ): Observable<GenericResponse<Auth.LoginResponse>> {
    return null;
  }

  @Post("auth/refresh-token")
  refreshToken(
    @Body refreshTokenRequest: Auth.RefreshTokenRequest
  ): Observable<GenericResponse<Auth.RefreshTokenResponse>> {
    return null;
  }

  @Post("auth/forgot-password")
  forgotPassword(
    @Body request: Auth.ForgotPasswordRequest
  ): Observable<GenericResponse<ForgotPasswordResponse>> {
    return null;
  }

  @Post("auth/register")
  register(
    @Body request: Auth.RegisterRequest
  ): Observable<GenericResponse<Auth.RegisterResponse>> {
    return null;
  }

  @Post("/emailconfirm")
  emailConfirm(
    @Body request: Auth.EmailConfirmRequest
  ): Observable<Auth.EmailConfirmResponse> {
    return null;
  }
  @Post("auth/send-email-confirmation-link")
  sendEmailConfirmationLink(@Body request: { email: string }): Observable<any> {
    return null;
  }

  @Post("auth/reset-password")
  resetPassword(
    @Body
    request: {
      email: string;
      password: string;
      resetPasswordToken: string;
    }
  ): Observable<any> {
    return null;
  }

  @Post("auth/confirm-email")
  confirmEmailLink(@Body request: any): Observable<GenericResponse<any>> {
    return null;
  }

  @Get("invitation/available-packages")
  AvailablePackages(): Observable<GenericResponse<Auth.PackageInfo[]>> {
    return null;
  }

  @Post("invitation/check-discount-code")
  checkDiscountCode(@Body request: string): Observable<any> {
    return null;
  }

  @Post("invitation/package-selection/{type}")
  packageSelection(
    @Body request: Auth.NewPackageSelectionRequest,
    @Path("type") type: number,
  ): Observable<any> {
    return null;
  }

  @Post("invitation/package-selection/{type}")
  packageSelection2(
    @Body request: Auth.PackageSelectionRequest,
    @Path("type") type: number,
  ): Observable<any> {
    return null;
  }

  @Get("invitation/available-marketplaces")
  AvailableMarketplaces(
    @Query("countryCode") countryCode: string
  ): Observable<GenericResponse<Auth.AvailableMarketPlaceResponse[]>> {
    return null;
  }

  @Post("invitation/exchange-amazon-code-to-lwa")
  ExchangeAmazonCode(
    @Query("code") code: string,
    @Query("countryCode") countryCode: string
  ): Observable<GenericResponse<Auth.AmazonResponse>> {
    return null;
  }

  @Post("invitation/save-marketplace-info/{type}")
  saveMarketplaceInfo(
    @Body request: any,
    @Path("type") type: number,
  ): Observable<any> {
    return null;
  }
  @Get("invitation/get-address-and-coupon-details")
  AddressCoupon(): Observable<GenericResponse<Auth.AddressCouponDetail>> {
    return null;
  }
}
