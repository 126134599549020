import { Injectable } from "@angular/core";
import { isAfter } from "date-fns";
import decode from "jwt-decode";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import snq from "snq";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  //DEPRECATED: TOKEN EXPIRATION HANDLES IN ERROR.INTERCEPTOR.TS CLASS
  isExpiredToken(encodedToken: any): boolean {
    const expirationDate = this.getTokenExpirationDate(encodedToken);
    const rightNow = new Date();
    const isExpiredToken = isAfter(rightNow, expirationDate);
    return isExpiredToken;
  }



  /**
   * return expiration date from token
   *
   * @param {string} encodedToken - base 64 token received from server and stored in local storage
   * @returns {date | null} returns expiration date or null id expired props not found in decoded token
   */
  getTokenExpirationDate(encodedToken: any): Date {
    if (!encodedToken) {
      return new Date(0); // is expired
    }

    const token: any = decode(encodedToken);
    if (!token.exp) {
      return new Date(0); // is expired
    }
    const expirationDate = new Date(token.exp * 1000);
    return expirationDate;
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (snq(() => !localStorage.getItem("accessToken"), false)) {
      localStorage.clear();
      this.router.navigate(["/authentication/signin"]);
      return false;
    } else if (snq(() => !localStorage.getItem("refreshToken"), false)) {
      localStorage.clear();
      this.router.navigate(["/authentication/signin"]);
      return false;
    } else {
      return true;
    }
  }

}
