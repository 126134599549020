import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { PageableResponse } from "../../models";
import { GetReceivedPayments } from "../../actions";
import { AccountManagementService } from "../../services";
import {
  ReceivedPaymentModel,
  ReceivedPaymentsResponseDto,
} from "../../../account/my-received-payments-table/my-received-payments-table.component";
import { ReceivedPaymentStateModel } from "../../models/account-management/my-received-payments-state-model";

@State<ReceivedPaymentStateModel>({
  name: "ReceivedPaymentState",
  defaults: {
    receivedPayments: null,
  },
})
@Injectable()
export class ReceivedPaymentsState {
  constructor(private accountManagementService: AccountManagementService) {}

  @Selector()
  static getReceivedPayments({
    receivedPayments,
  }: ReceivedPaymentStateModel): ReceivedPaymentsResponseDto {
    return receivedPayments;
  }

  @Action(GetReceivedPayments)
  getReceivedPayments(
    { patchState }: StateContext<ReceivedPaymentStateModel>,
    { payload }: GetReceivedPayments
  ) {
    return this.accountManagementService
      .getReceivedPayments(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ receivedPayments: res?.Result });
        })
      );
  }
}
