import { Store } from "@ngxs/store";
import { Resolve } from "@angular/router";
import { Injectable } from "@angular/core";
import { GetAvailableWareHouses } from "../actions";
import { mapTo, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DropyHouseResolver implements Resolve<null> {
  constructor(private store: Store) {}

  resolve() {
    return this.store
      .dispatch([new GetAvailableWareHouses()])
      .pipe(take(1), mapTo(null));
  }
}
