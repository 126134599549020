import { PriceTableModel } from "../models";
import { UserProductRequest } from "../models/inventory";

export class GetMarketPlaces {
  static readonly type = "[AccountManagement] Get Market Places";
}

export class GetAccountInformation {
  static readonly type = "[AccountManagement] Get Account Information";
}

export class GetSubscriptionDetails {
  static readonly type = "[AccountManagement] Get Subscription Details";
}

export class GetReceivedPayments {
  static readonly type = "[Inventory] Get Received Payments";

  constructor(public readonly payload: UserProductRequest) {}
}

export class GetFilters {
  static readonly type = "[Account] Get Filters";
}

export class GetPricesTable {
  static readonly type = "[Account] Get Prices Table";
  constructor(public readonly payload: number) {}
}

export class UpdatePricesTable {
  static readonly type = "[Account] Update Prices Table";

  constructor(
    public readonly payload: PriceTableModel,
    public readonly type: number
  ) {}
}
