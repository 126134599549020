import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  CategoryDashboard,
  DashboadInfo,
  DashboardStateModel,
  SaleSummaryResponse,
} from "../models/dashboard";
import { DashboardService } from "../services";
import {
  GetCategories,
  GetCategoriesForDashboard,
  GetDashboardInfo,
  GetSalesSummary,
} from "../actions";
import { GetCategoryRequest } from "../models/inventory/requests/get-category";
import { PageableResponse } from "../models";

@State<DashboardStateModel>({
  name: "DashboardState",
  defaults: {
    dashboardInfo: null,
    salesSummary: [],
    categories: [],
    categoriesWithPage: null,
  },
})
@Injectable()
export class DashboardState {
  constructor(private dashboardService: DashboardService) {}

  @Selector()
  static dashboardInfo({ dashboardInfo }: DashboardStateModel): DashboadInfo {
    return dashboardInfo;
  }

  @Selector()
  static salesSummary({
    salesSummary,
  }: DashboardStateModel): SaleSummaryResponse[] {
    return salesSummary;
  }

  @Selector()
  static categories({ categories }: DashboardStateModel): CategoryDashboard[] {
    return categories;
  }

  @Selector()
  static getCategories({
    categoriesWithPage,
  }: DashboardStateModel): PageableResponse<CategoryDashboard> {
    return categoriesWithPage;
  }

  @Action(GetDashboardInfo)
  getDashboardInfo(
    { patchState }: StateContext<DashboardStateModel>,
    { payload }: GetDashboardInfo
  ) {
    return this.dashboardService
      .getDashboardInfo(localStorage.getItem("selectedMarketPlaceId"), payload)
      .pipe(
        tap((res) => {
          patchState({ dashboardInfo: res?.Result });
        })
      );
  }

  @Action(GetSalesSummary)
  getSalesSummary(
    { patchState }: StateContext<DashboardStateModel>,
    { payload }: GetSalesSummary
  ) {
    return this.dashboardService
      .getSalesSummary(localStorage.getItem("selectedMarketPlaceId"), payload)
      .pipe(
        tap((res) => {
          patchState({ salesSummary: res?.Result });
        })
      );
  }

  @Action(GetCategoriesForDashboard)
  getCategoriesForDashboard(
    { patchState }: StateContext<DashboardStateModel>,
    { payload }: GetCategoriesForDashboard
  ) {
    return this.dashboardService
      .getCategoriesForDashboard(
        localStorage.getItem("selectedMarketPlaceId"),
        payload
      )
      .pipe(
        tap((res) => {
          patchState({ categories: res?.Result?.data || [] });
        })
      );
  }

  @Action(GetCategories)
  getCategories(
    { patchState }: StateContext<DashboardStateModel>,
    { payload }: GetCategories
  ) {
    return this.dashboardService
      .getCategories(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          patchState({ categoriesWithPage: res || [] });
        })
      );
  }
}
