import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  GenericResponse,
  PageableResponse,
  RestrictionStateModel,
} from "../../models";
import { InventoryService } from "../../services/inventory.service";
import {
  DoesntMeetCriteriaStateModel,
  PendingApprovalStateModel,
  UserProductWithProductDto,
} from "../../models/inventory";
import {
  AdvancedSearchApproval,
  ApprovePending,
  GetPendingApprovals,
  RemoveAdvancedSearchApproval,
  ApproveAdvancedSearchPending,
  RemoveFromStore,
  RemoveImportedToStore,
  RemovePending,
} from "../../actions";

@State<PendingApprovalStateModel>({
  name: "PendingApprovalState",
  defaults: {
    pendingApprovals: null,
  },
})
@Injectable()
export class PendingApprovalState {
  constructor(private inventoryService: InventoryService) {}

  @Selector()
  static getPendingApprovals({
    pendingApprovals,
  }: PendingApprovalStateModel): PageableResponse<UserProductWithProductDto> {
    return pendingApprovals;
  }

  @Selector()
  static removeFromStore({
    createCategoryBlackListResponse,
  }: RestrictionStateModel): GenericResponse<null> {
    return createCategoryBlackListResponse;
  }

  @Action(GetPendingApprovals)
  getPendingApprovals(
    { patchState }: StateContext<PendingApprovalStateModel>,
    { payload }: GetPendingApprovals
  ) {
    return this.inventoryService
      .getPendingApprovals(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize
      )
      .pipe(
        tap((res) => {
          localStorage.setItem("isAdvanced", "0");
          patchState({ pendingApprovals: res });
        })
      );
  }

  @Action(AdvancedSearchApproval)
  advancedSearchApproval(
    { patchState }: StateContext<PendingApprovalStateModel>,
    { payload }: AdvancedSearchApproval
  ) {
    return this.inventoryService
      .advancedSearchPendingApproval(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.pageNumber,
        payload.pageSize,
        payload.data
      )
      .pipe(
        tap((res) => {
          localStorage.setItem("isAdvanced", "1");
          patchState({ pendingApprovals: res });
        })
      );
  }

  @Action(RemoveAdvancedSearchApproval)
  removeAdvancedPendingApproval(
    { patchState }: StateContext<PendingApprovalStateModel>,
    { payload }: RemoveAdvancedSearchApproval
  ) {
    return this.inventoryService
      .removeAdvancedSearchPendingApproval(
        localStorage.getItem("selectedMarketPlaceId"),
        payload.data
      )
      .pipe(
        tap((res) => {
        })
      );
  }

  @Action(ApprovePending)
  approvePending(
    { patchState }: StateContext<PendingApprovalStateModel>,
    { payload, type }: ApprovePending
  ) {
    return this.inventoryService.approvePending(
      localStorage.getItem("selectedMarketPlaceId"),
      type.toString(),
      payload.asins
    );
  }


  @Action(ApproveAdvancedSearchPending)
  approveAdvancedSearchPending(
    { patchState }: StateContext<PendingApprovalStateModel>,
    { payload }: ApproveAdvancedSearchPending
  ) {
    return this.inventoryService.approveAdvancedSearchPending(
      localStorage.getItem("selectedMarketPlaceId"),
      payload.data
    );
  }

  @Action(RemovePending)
  removePending(
    { patchState }: StateContext<PendingApprovalStateModel>,
    { payload, type }: RemovePending
  ) {
    return this.inventoryService.removePending(
      localStorage.getItem("selectedMarketPlaceId"),
      type.toString(),
      payload.asins
    );
  }
}
